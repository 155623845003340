import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import GroupList from "./PredictionsListGroup";
import Swal from "sweetalert2";
import { uploadFileToS3 } from "../../../config/config";

export default function DashboardPic ({  userAdminGroups, userIsGroupAdmin, userGroups, allGroups }) {
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");

  const handleGroupSelect = (groupId) => {
    // Handle the selected group ID here
    setSelectedGroupId(groupId);
  };

  const handleSavePic = async () => {
    try {
      Swal.fire("Nice!", "(coming soon)", "success");
    } catch (error) {
      console.error("Error while saving announcement:", error);
    }
  };

  const updateImage = async (imageUrl, groupId) => {
    const response = await fetch(
      `${process.env.REACT_APP_PREDICTIONS_API}/api/common-images`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image: imageUrl,
          groupId: groupId, 
        }),
      }
    );
    forceUpdate();
    console.log(response.json(), "Common Image Response");

  };

  const forceUpdate = () => {
    // Use the useState hook to force a re-render
    setImageFile((prevImageFile) => prevImageFile);
  };

  const handleUploadClick = () => {
    if (!selectedGroupId) {
      // Show a Swal indicating that a group must be selected
      Swal.fire("Oops!", "Please select a group first.", "error");
      return;
    }
    // Check if a file is selected before initiating the upload
    if (!imageFile) {
      Swal.fire("Oops!", "Please select an image first.", "error");
      return;
    }

    const fileName = `common-images/${Date.now()}`;
    uploadFileToS3(
      imageFile,
      (data) => {
        updateImage(data, selectedGroupId);
        Swal.fire("Yay!", "Added to picture gallery.", "success").then(() => {
          window.location.reload();
        });
      },
      fileName
    );
  };
  
  return (
    <div className="container-fluid">
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
                <form className="profile-form">
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #DFDFDF",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="card-body">
                      <GroupList userAdminGroups={userAdminGroups} userIsGroupAdmin={userIsGroupAdmin} allGroups={allGroups}
                        userGroups={userGroups}
                        onGroupSelect={handleGroupSelect}
                      />
                      &nbsp;
                    </div>
                    <div className="mt-3">
                      <label htmlFor="fileUpload" className="form-label">
                        <label className="form-label">
                          Select a pic:?
                        </label>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="fileUpload"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                  &nbsp;
                  <div className="row">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUploadClick}
                    >
                      Share this pic
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
}
