import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { IMAGES } from "../../constant/theme";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import axios from "axios";

const Announcements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [announcementList, setAnnouncementList] = useState([]);

  const fetchAnnouncements = async () => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    let retries = 0;
    const maxRetries = 5; // Adjust as needed

    while (retries < maxRetries) {
      try {
        let groupName = localStorage.getItem("user_initial_Group");

        // Retry only if groupName is not set
        if (!groupName) {
          retries++;
          console.log(`Retrying fetchAnnouncements - Attempt ${retries}`);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
          continue;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/announcements/${groupName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          if (response.data.length > 0) {
            const filterData = response.data.filter(
              (item) => item !== null && item
            );
            setAnnouncementList(filterData);
          }
        } else {
          console.error("Failed to fetch announcements");
        }
        break;
      } catch (error) {
        console.error("Error fetching announcements:", error);
        retries++;
        console.log(`Retrying fetchAnnouncements - Attempt ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      }
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchAnnouncements();

    // // Set up an interval to fetch data every 5 minutes (adjust as needed)
    // const intervalId = setInterval(() => {
    //   fetchAnnouncements();
    // }, process.env.REACT_APP_REFRESH_RATE);

    // // Clean up the interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  const storedUserColor = localStorage.getItem("userDetails");
  const storedDetailsColor = JSON.parse(storedUserColor);
  const storedRoleColor = storedDetailsColor.data.role;
  // #F0E663
  const bgCustom_Announcements = storedRoleColor.includes("Admin")
    ? "#01152F"
    : "#01152F";

  return (
    <div className="container-fluid"style={{ background: '#01152F' }}>
      <div className="element-area"style={{ background: '#01152F' }}>
        <div className="demo-view" style={{ background: '#01152F' }}>
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0"
          style={{ background: '#01152F' }} >
            <Row>
              <Col xl={12}>
                <div
                  className="card"
                >
                  <div className="card-body p-0">
                    <div className="table-responsive active-projects manage-client">
                      <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                        <h4 className="heading mb-0">Announcements</h4>
                        <div>
                          <Link
                            to="/add-announcement"
                            style={{
                              color: "blue",
                            }}
                          >
                            add yours
                          </Link>
                        </div>
                      </div>
                      <div
                        id="manage-tblwrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="reports-tbl"
                          className="table ItemsCheckboxSec dataTable no-footer mb-0"
                        >
                          <tbody>
                            {announcementList.slice(0, 4).map((item, index) => (
                              <tr
                                key={index}
                              >
                                <td
                                  style={{
                                    verticalAlign: "top",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <div
                                    className="products"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    <div
                                      style={{
                                        minHeight: "50px",
                                        marginTop: "5px",
                                        padding: "4px",
                                      }}
                                    >
                                      {item?.image && (
                                        <img
                                          src={item?.image || IMAGES.contact1}
                                          className="avatar avatar-md"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        minHeight: "60px",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      <span style={{ fontSize: "10px" }}>
                                        {item?.time}
                                      </span>
                                      <h6 style={{ fontSize: "14px" }}>
                                        {item.description.includes('has joined the group') ? (
                                          <div>
                                            Group Announcement
                                          </div>
                                        ) : (
                                          <div>
                                            {item.title}
                                          </div>
                                        )}
                                      </h6>
                                      <p
                                        style={{
                                          fontSize: "13px",
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {item.description}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "20px", background: '#FFFFFF' }}> 
                    <h6> </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
};

export default Announcements;
