//AppProfile/QuestionTemplate.js

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import TemplateListGroup from "../../../components/bootstrap/TemplateListGroup";
import HistoryPredictionsView from "../../../components/bootstrap/HistoryPredictionsView";
import HistoryGoalsView from "../../../components/bootstrap/HistoryGoalsView";
import HistoryBucketlistView from "../../../components/bootstrap/HistoryBucketlistView";
import GroupListAdmin from '../../../components/bootstrap/PredictionsListGroupAdmin';
import { v4 as uuidv4 } from "uuid";
import axios from "axios"; // Import Axios

const QuestionsTemplate = ({  userAdminGroups, userIsGroupAdmin, userGroups }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('0');
  const handleGroupSelect = (groupId) => {
      // Handle the selected group ID here
      setSelectedGroupId(groupId);
  };
  const [storedRoleEnhanced, setStoredRoleEnhanced] = useState('?');
  const currentDate = new Date();
  const currentYearVal = currentDate.getFullYear();
  const [currentYear, setCurrentYear] = useState(currentYearVal);
  const [pastYear, setPastYear] = useState(currentYearVal-1);
  const [nextYear, setNextYear] = useState(currentYearVal+1);
  const [predictionsYear, setPredictionsYear] = useState(
    new Date().getFullYear()
  );
  const [goalsYear, setGoalsYear] = useState(new Date().getFullYear());
  const [bucketlistYear, setBucketlistYear] = useState(
    new Date().getFullYear()
  );

  const storedUser = localStorage.getItem("userDetails");
  const storedDetails = JSON.parse(storedUser);
  const storedRole = storedDetails.data.role;
  const storedUserId = storedDetails.data.id;

  const [predictionsByYear, setPredictionsByYear] = useState({});
  const [currentPredictions, setCurrentPredictions] = useState({ predictions: [] });
  const [pastPredictions, setPastPredictions] = useState({ predictions: [] });
  const [nextPredictions, setNextPredictions] = useState({ predictions: [] });

  const [goalsByYear, setGoalsByYear] = useState({});
  const [currentGoals, setCurrentGoals] = useState({ predictions: [] });
  const [pastGoals, setPastGoals] = useState({ predictions: [] });
  const [nextGoals, setNextGoals] = useState({ predictions: [] });
  
  const [templatePredictionsMap, setTemplatePredictionsMap] = useState();
  const [templateGoalsMap, setTemplateGoalsMap] = useState();

  const [templateBucketlistMap, setTemplateBucketlistMap] = useState();
  const [bucketlistByYear, setBucketlistByYear] = useState({});
  const [currentBucketlist, setCurrentBucketlist] = useState({
    predictions: [],
  });
  const [pastBucketlist, setPastBucketlist] = useState({ predictions: [] });

  const [isLoading, setIsLoading] = useState(true);

  const tokenDetailsString = localStorage.getItem("userDetails");
  const tokenDetails = JSON.parse(tokenDetailsString);
  const token = tokenDetails.token;

  const fetchRoleData = async () => {
    try {
      const chkrUserRole = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroupsAdmin/${storedUserId}`);
      const userGroupsInfo = chkrUserRole.data.data.userGroups;
      const hasGroupAdminRole = userGroupsInfo.some(group => group.role === 'groupAdmin');

      if (hasGroupAdminRole) {
        setStoredRoleEnhanced('groupAdmin');
        //storedRole = storedRoleEnhanced;
      } else{
        setStoredRoleEnhanced(storedRole);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    const fetchData = async () => {
      let groupName = localStorage.getItem("user_initial_Group");

      try {
        //Goals
        const mapGoal = await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/template-goals/${groupName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const templateGoalsMap = await mapGoal.json();
        
        templateGoalsMap.forEach((goal) => {
          const year = goal.year;
          goalsByYear[year] = [];

          goalsByYear[year].push(goal);
        });

        Object.keys(goalsByYear).forEach((year) => {
          const predictions = goalsByYear[year][0].questions.map((question) => {
            setGoalsYear(goalsYear === 1970 ? year : goalsYear);
            const prediction = {
              questionId: question._id,
              // Add more fields as needed
            };

            if (question.taskid && question.text) {
              prediction.taskId = question.taskid;
              prediction.text = question.text;
            }

            return prediction;
          });

          if (parseInt(year, 10) === pastYear) {
            setPastGoals({ predictions });
          }
          if (parseInt(year, 10) === currentYear) {
            setCurrentGoals({ predictions });
          }
          if (parseInt(year, 10) === nextYear) {
            setNextGoals({ predictions });
          }
        });

        //Predictions
        const mapPrediction = await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/template-predictions`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const templatePredictionsMap = await mapPrediction.json();
        // console.log("templatePredictionsMap:", templatePredictionsMap);

        templatePredictionsMap.forEach((prediction) => {
          const yearPrediction = prediction.year;
          predictionsByYear[yearPrediction] = [];

          predictionsByYear[yearPrediction].push(prediction);
        });

        // console.log("predictionsByYear:", predictionsByYear);
        Object.keys(predictionsByYear).forEach((yearPrediction) => {
          setPredictionsYear(
            predictionsYear === 1970 ? yearPrediction : predictionsYear
          );
          const predictions = predictionsByYear[
            yearPrediction
          ][0].questions.map((question) => {
            const prediction = {
              questionId: question._id,
              // Add more fields as needed
            };

            if (question.taskid && question.text) {
              prediction.taskId = question.taskid;
              prediction.text = question.text;
            }

            return prediction;
          });
          if (parseInt(yearPrediction, 10) === pastYear) {
            setPastPredictions({ predictions });
            // console.log("predictions:", yearPrediction, predictions);  
          }          
          if (parseInt(yearPrediction, 10) === currentYear) {
            setCurrentPredictions({ predictions });
            // console.log("predictions:", yearPrediction, predictions);  
          }
          if (parseInt(yearPrediction, 10) === nextYear) {
            setNextPredictions({ predictions });
            // console.log("predictions:", yearPrediction, predictions);  
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    //
  }, [predictionsByYear, goalsByYear, bucketlistByYear]);

  useEffect(() => {
    // Add a global style to improve responsiveness of Swal modals
    const style = document.createElement("style");
    style.innerHTML = `
      .sw-responsive {
        max-width: 80%;
      }

      @media (max-width: 576px) {
        .sw-responsive {
          max-width: 95%;
        }
      }

      .swal2-html-container {
        max-width: 100%;
      }
    `;
    document.head.appendChild(style);

    // Remove the style on component unmount
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleButtonClick = async (category, questions, useYear) => {
    let displayYear =
      category === "Predictions"
        ? useYear
        : category === "Goals"
        ? useYear
        : category === "Bucketlist"
        ? '?'
        : "-";

    let groupQuestions = [];
    let groupQuestionList = [];
    
    if (storedRole === "groupAdmin") {
      const memberId = storedDetails.data.id;
      const encodedMemberId = encodeURIComponent(memberId);

      if (category === "Predictions") {
        const response = await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/get-yealy-predictions/${displayYear}/${encodedMemberId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          // Use response.json() to extract the JSON content
          groupQuestions = await response.json();
          groupQuestionList = groupQuestions.groups[0];
        } else {
          // Handle the error here
          console.error(
            "Failed to fetch predictions. Status:",
            response.status
          );
        }
      }
    }

    let groupFields = [];

    //const numInputFields = process.env.REACT_APP_MAX_QUESTIONS;
    const numInputFields =
      category === "Predictions"
        ? process.env.REACT_APP_MAX_PREDICTIONS
        : process.env.REACT_APP_MAX_QUESTIONS;

        // console.log(">> user groupFields 0");
        // console.log(">> user groupFields 0", groupQuestionList);
        // console.log(">> user groupFields 0", groupQuestionList.questions);
        if (groupQuestionList && groupQuestionList.predictions && groupQuestionList.predictions.length > 0) {
          const questionsArray = groupQuestionList.predictions[0].questions;
        
          groupFields = Array.from({ length: numInputFields }, (_, index) => {
            if (index < questionsArray.length) {
              const valueid = questionsArray[index].taskid || 0;
              const value = questionsArray[index].text || "";
              if(storedRole === "globalAdmin" || storedRole === "groupAdmin"){
                return `<input type="text" class="swal2-input" id="${valueid}" name="${valueid}" maxlength="150" style="width: 80%; font-size: 12px;" value="(new entry)">`;
              } else {
                return `<input type="text" class="swal2-input" id="${valueid}" name="${valueid}" maxlength="150" style="width: 80%; font-size: 12px;" value="${value}">`;
              }
            } else {
              // For the extra fields, use a default value
              const placeholder = `new entry ${index - numInputFields + 1}`;
              return `<input type="text" class="swal2-input" placeholder="${placeholder}" maxlength="150" style="width: 80%; font-size: 12px;" value="(new entry)">`;
            }
          }).join("");
        }
        
        // console.log("groupQuestionList:", groupQuestionList);
        // console.log("groupFields:", groupFields, groupFields.length); 

    let combinedFields = [];
    // Check if groupFields has data before concatenating
    if (groupFields.length > 0) {
//console.log(">> user groupFields");
      combinedFields = groupFields;
    } else {
//console.log(">> user predictionsFields");
//console.log(">>questions:", questions);
      const predictionsFields = Array.from(
        { length: numInputFields },
        (_, index) => {
          if (index < questions.predictions.length) {
            const valueid = questions.predictions[index].taskid || uuidv4();
            const value = questions.predictions[index].text || "";
              if(storedRole === "globalAdmin" || storedRole === "groupAdmin"){
                return `<input type="text" class="swal2-input" id="${valueid}" name="${valueid}" maxlength="150" style="width: 80%; font-size: 12px;" value="(new entry)">`;
              } else {
                return `<input type="text" class="swal2-input" id="${valueid}" name="${valueid}" maxlength="150" style="width: 80%; font-size: 12px;" value="${value}">`;
              }
          } else {
            // For the extra 5 fields, use a default value
            const placeholder = `new entry ${
              index - questions.predictions.length + 1
            }`;
            return `<input type="text" class="swal2-input" placeholder="${placeholder}" maxlength="150" style="width: 80%; font-size: 12px;" value="(new entry)">`;
          }
        }
      );

      combinedFields = predictionsFields;
    }

//console.log(">>combinedFields:", combinedFields); 

    if (storedRole === "globalAdmin") {
      Swal.fire({
        title: `${category}`,
        html: `<div style="max-height: 400px; overflow-y: auto; font-size: 12px;">${combinedFields}</div>`,
        showCloseButton: false,
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        denyButtonColor: "#00CC00",
        denyButtonText: "Publish",
        confirmButtonText: "Save Draft",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        customClass: {
          htmlContainer: "swal2-html-container",
          popup: "swal2-popup sw-responsive",
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
          denyButton: "btn btn-success",
          width: "80%",
        },
        preConfirm: async () => {
          Swal.fire("", "(coming soon).", "success");
        },
        preDeny: async () => {
          if (category === "Predictions") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let predictionArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/admin-update-yealy-templates/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: predictionArray,
                  mode: "published",
                  category: category,
                }),
              }
            );
          } else if (category === "Goals") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let goalsArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/admin-update-yealy-templates/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: goalsArray,
                  mode: "published",
                  category: category,
                }),
              }
            );
          } else if (category === "Bucketlist") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let bucketlistArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/admin-update-yealy-templates/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: bucketlistArray,
                  mode: "published",
                  category: category,
                }),
              }
            );
          }
          Swal.fire("Thanks!", `${category} Template Updated`, "success").then((result) => {
            if (result.isConfirmed) {
              // Reload the page only when the user presses "OK"
              window.location.reload();
            }
          });
        },
      });
    } else {
      // groupAdmin
      Swal.fire({
        // title: `${displayYear} ${category} [${selectedGroupId}]`,
        title: `${displayYear} ${category}`,
        html: `<div style="max-height: 400px; overflow-y: auto; font-size: 12px;">${combinedFields}</div>`,
        showCloseButton: false,
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        denyButtonColor: "#00CC00",
        denyButtonText: "Publish",
        confirmButtonText: "Save Draft",
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        customClass: {
          htmlContainer: "swal2-html-container",
          popup: "swal2-popup sw-responsive",
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
          denyButton: "btn btn-success",
          width: "80%",
        },
        preConfirm: async () => {
          if (category === "Predictions") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let predictionArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-predictions/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: predictionArray,
                  mode: "draft",
                }),
              }
            );
          } else if (category === "Goals") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let goalsArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" || inputElement.name === "0"
                        ? uuidv4()
                        : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-goals/${useYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: goalsArray,
                  mode: "draft",
                }),
              }
            );
          } else if (category === "Bucketlist") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let bucketlistArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-bucketlist/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: bucketlistArray,
                  mode: "draft",
                }),
              }
            );
          }
          Swal.fire("Thanks!", `Group ${category} Updated`, "success").then((result) => {
            if (result.isConfirmed) {
              // Reload the page only when the user presses "OK"
              window.location.reload();
            }
          });
        },
        preDeny: async () => {
          // // Publish Button
          if (category === "Predictions") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let predictionArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-predictions/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: predictionArray,
                  mode: "published",
                }),
              }
            );
          } else if (category === "Goals") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let goalsArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" || inputElement.name === "0"
                        ? uuidv4()
                        : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-goals/${useYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: goalsArray,
                  mode: "published",
                }),
              }
            );
          } else if (category === "Bucketlist") {
            const inputElements = document.querySelectorAll(".swal2-input");
            let bucketlistArray = [...inputElements]
              .map((inputElement) => {
                if (
                  inputElement.value !== "(new entry)" &&
                  inputElement.value !== "" &&
                  inputElement.value !== " "
                ) {
                  return {
                    text: inputElement.value,
                    taskid:
                      inputElement.name === "" ? uuidv4() : inputElement.name,
                  };
                }
              })
              .filter((item) => item);

            const memberId = storedDetails.data.id;
            const encodedMemberId = encodeURIComponent(memberId);
            const response = await fetch(
              `${process.env.REACT_APP_PREDICTIONS_API}/api/update-yealy-bucketlist/${displayYear}/${encodedMemberId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  questions: bucketlistArray,
                  mode: "published",
                }),
              }
            );
          }
          Swal.fire("Thanks!", `Group ${category} Updated`, "success").then((result) => {
            if (result.isConfirmed) {
              // Reload the page only when the user presses "OK"
              window.location.reload();
            }
          });
        },
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {/* Left side */}
            <form className="profile-form">
              <div className="card-body">
                {/*
                <div className="card-body"> 
                  <GroupListAdmin allGroups={allGroups} userGroups={userGroups} onGroupSelect={handleGroupSelect} />
                </div> 
                */}
                <h6>
                  &nbsp;{storedRole === "globalAdmin" ? "Global" : "Groups"}{" "}
                  Template
                </h6>
                <div className="row">
                {(storedRole === "groupAdmin" || storedRoleEnhanced === "groupAdmin" || storedRole === "globalAdmin") && (
                  <button
                    type="button"
                    variant="primary"
                    className="btn btn-primary dz-xs-flex m-r5"
                    style={{
                      backgroundColor: "#808080",
                      width: "90px",
                      height: "84px",
                      margin: "2px",
                      padding: "0px",
                    }}
                    onClick={() =>
                      handleButtonClick("Predictions", pastPredictions, pastYear)
                    }
                  >
                    {pastYear} Predictions
                  </button>
                )}   
                &nbsp;
                <button
                  type="button"
                  variant="primary"
                  className="btn btn-primary dz-xs-flex m-r5"
                  style={{
                    backgroundColor: "#6B8DEF",
                    width: "90px",
                    height: "84px",
                    margin: "2px",
                    padding: "0px",
                  }}
                  onClick={() =>
                    handleButtonClick("Predictions", currentPredictions, currentYear)
                  }
                >
                  {currentYear} Predictions
                </button>
                &nbsp;
                {(storedRole === "groupAdmin" || storedRoleEnhanced === "groupAdmin" || storedRole === "globalAdmin") && (
                  <button
                    type="button"
                    variant="primary"
                    className="btn btn-primary dz-xs-flex m-r5"
                    style={{
                      backgroundColor: "#808080",
                      width: "90px",
                      height: "84px",
                      margin: "2px",
                      padding: "0px",
                    }}
                    onClick={() =>
                      handleButtonClick("Predictions", nextPredictions, nextYear)
                    }
                  >
                    {nextYear} Predictions
                  </button>
                )} 
                &nbsp;
                </div>
                <div className="row">
                {(storedRole === "groupAdmin" || storedRoleEnhanced === "groupAdmin" || storedRole === "globalAdmin") && (
                  <button
                    type="button"
                    variant="primary"
                    className="btn btn-primary dz-xs-flex m-r5"
                    style={{
                      backgroundColor: "#808080",
                      width: "90px",
                      height: "84px",
                      margin: "2px",
                      padding: "0px",
                    }}
                    onClick={() =>
                      handleButtonClick("Goals", pastGoals, pastYear)
                    }
                  >
                    {pastYear} Goals
                  </button>
                )} 
                &nbsp;
                <button
                  type="button"
                  variant="primary"
                  className="btn btn-primary dz-xs-flex m-r5"
                  style={{
                    backgroundColor: "#6B8DEF",
                    width: "90px",
                    height: "84px",
                    margin: "2px",
                    padding: "0px",
                  }}
                  onClick={() =>
                    handleButtonClick("Goals", currentGoals, currentYear)
                  }
                >
                  {currentYear} Goals
                </button>
                &nbsp;
                {(storedRole === "groupAdmin" || storedRoleEnhanced === "groupAdmin" || storedRole === "globalAdmin") && (
                  <button
                    type="button"
                    variant="primary"
                    className="btn btn-primary dz-xs-flex m-r5"
                    style={{
                      backgroundColor: "#808080",
                      width: "90px",
                      height: "84px",
                      margin: "2px",
                      padding: "0px",
                    }}
                    onClick={() =>
                      handleButtonClick("Goals", nextGoals, nextYear)
                    }
                  >
                    {nextYear} Goals
                  </button>
                )} 
                &nbsp;
                </div>
              </div>
              &nbsp;
            </form>
            <div></div>
          </div>
          <div className="col-md-6">
            {/* Right side */}
            &nbsp;
            <div
              className="card profile-card card-bx"
              style={{ padding: "10px" }}
            >
              <div><h5>Global Template</h5></div>
            
              <details>
                <summary
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                    fontSize: "14px",
                  }}
                >
                  {pastYear} Predictions
                </summary>
                <HistoryPredictionsView
                  year={pastYear} predictions={pastPredictions} 
                />
              </details>
              
              <details>
                <summary
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                    fontSize: "14px",
                  }}
                >
                  {currentYear} Predictions
                </summary>
                <HistoryPredictionsView 
                  year={currentYear} predictions={currentPredictions} 
                />
              </details>
              
              <details>
                <summary
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                    fontSize: "14px",
                  }}
                >
                  {nextYear} Predictions
                </summary>
                <HistoryPredictionsView 
                  year={nextYear} predictions={nextPredictions} 
                />
              </details>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsTemplate;
