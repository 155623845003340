import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { navtoggle } from "../../../store/actions/AuthActions";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };

  return (
    <div className="nav-header" style={{ background: '#01152F', border: "2px solid #01152F" }}>
      <Link to="/dashboard" className="brand-logo">
        <img src="/mascot-logo.JPG" style={{ borderRadius: '5px' }} alt="New Year's Eve : Predictions and Goals" width="36" height="36" />
      </Link>
    </div>
  );
};

export default NavHader;
