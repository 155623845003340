import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    //`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    //`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    `${process.env.REACT_APP_PREDICTIONS_API}/api/login`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.message) {
    case "Invalid password":
      swal("Oops", "Login failed. Please try again.", "error");
      break;
      case "User not found":
        swal("Oops", "Login failed. Please try again.", "error");
        break;
      case "User Not Found":
        swal("Oops", "Login failed. Please try again.", "error");
        break;
    case "Invalid Credentials":
      swal("Oops", "Login failed. Please try again.", "error");
      break;

    case "EMAIL_EXISTS":
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + process.env.REACT_APP_LOGIN_EXPIRES * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    //dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
  const tokenDetailsString = localStorage.getItem("userDetails");
  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}

export async function updateUserProfile(token, payload) {
  const userData = localStorage.getItem("userDetails");
  const tokenDetailsString = JSON.parse(userData);

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_PREDICTIONS_API}/api/change-profile`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/member-connect`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: tokenDetailsString.data.id,
        groupId: "abcdef",
        mode: "015",
      }),
    });
    // Show success message using swal
    swal({
      text: "Profile updated successfully",
      icon: "success",
      buttons: {
        done: {
          text: "Done",
          value: "done",
        },
      },
    }).then((value) => {
      if (value === "done") {
        // Handle the "Done" button click if needed
      }
    });

    return response; // Assuming you want to return the response
  } catch (error) {
    // Show error message using swal
    swal({
      text: "Oops! Failed to update profile",
      icon: "error",
      buttons: {
        sorry: {
          text: "Sorry, let's try again",
          value: "Sorry, let's try again",
        },
      },
      dangerMode: true,
    });

    throw error; // Re-throw the error for further handling if needed
  }
}
