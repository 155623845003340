import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';

import { IMAGES, SVGICON } from "../../constant/theme";
import LogoutPage from "./Logout";
import Logoutbtn from "./Logoutbtn";

import { useSelector, useDispatch } from "react-redux";

import { setCurrentUser } from "../../../store/actions/UserActions";

const UserMenu = ({ onNote }) => {
  const [headerFix, setHeaderFix] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userIsGroupAdmin, setUserIsGroupAdmin] = useState(false);
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const [loading, setLoading] = useState(true);

  const fetchUserDetailsFromLocalStorage = async () => {
    const storedUserDetails = localStorage.getItem("userDetails");

    let storedData = null;
    if (storedUserDetails) {
      try {
        storedData = JSON.parse(storedUserDetails).data;
      } catch (error) {
        storedData = null;
      }
    }

    if (storedData) {
      const updatedUser = {
        email: storedData.email,
        names: storedData.names,
        role: storedData.role,
        accessToken: storedData.accessToken,
        pic: storedData.pic,
      };

      dispatch(setCurrentUser(updatedUser));
    }
  };

  const state = useSelector((state) => state);
  
  const storedUserDetails = localStorage.getItem("userDetails");
  const storedData = JSON.parse(storedUserDetails).data;
  
  useEffect(() => {
    
    const fetchUserGroups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroupsAdmin/${storedData.id}`);
        const userGroupsInfo = response.data.data.userGroups;
        
        // Check if the user has the role of 'groupAdmin' in any group
        const hasGroupAdminRole = userGroupsInfo.some(group => group.role === 'groupAdmin');

        // Set the local flag based on the result
        setUserIsGroupAdmin(hasGroupAdminRole);
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    // Fetch user groups when the component mounts
    fetchUserGroups();
  },[storedData.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserDetailsFromLocalStorage();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setHeaderFix(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDropdownSelect = () => {
    // Close the dropdown
    setDropdownOpen(false);
  };

  const handleDropdownToggle = (isOpen, event, metadata) => {
    // Set the dropdown state based on the toggle event
    setDropdownOpen(isOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser || currentUser === null) {
    return <div>Error fetching user details.</div>;
  }

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content" style={{ background: '#01152F', border: "2px solid #01152F" }}>
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <h2 style={{ color: "#FFF" }}>
                <Link to="/dashboard" className="brand-logo" style={{ color: "#FFF" }}>
                  <span style={{ color: "#F1D34F" }}>NYE Predictions</span>
                </Link>
              </h2>
            </div>
            <ul className="navbar-nav header-right">
              <li className="nav-item align-items-center header-border">
                <Logoutbtn />
              </li>
              <li className="nav-item ps-3">
                <Dropdown
                  className="header-profile2"
                  onSelect={handleDropdownSelect}
                  onToggle={handleDropdownToggle}
                  show={dropdownOpen}
                >
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media">
                        <img
                          src={
                            currentUser.pic && currentUser.pic !== ""
                              ? currentUser.pic
                              : IMAGES.Tab1
                          }
                          alt=""
                          style={{ height: '115%', width: '115%'}}
                        />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="card border-0 mb-0">
                      <div className="card-header py-2">
                        <div className="products">
                          <img
                            src={
                              currentUser.pic && currentUser.pic !== ""
                                ? currentUser.pic
                                : IMAGES.Tab1
                            }
                            className="avatar avatar-md"
                            alt=""
                          />
                          <div>
                            <h6>{currentUser.names}</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-body px-0 py-2"
                        onClick={handleDropdownSelect}
                      >
                        <Link
                          to={"/member-profile"}
                          className="dropdown-item ai-icon"
                        >
                          {SVGICON.User} <span className="ms-2">Profile </span>
                        </Link>
                      </div>
                      {/*
                      <div
                        className="card-body px-0 py-2"
                        onClick={handleDropdownSelect}
                      >
                        <Link
                          to={"/bucketlist"}
                          className="dropdown-item ai-icon"
                        >
                          {SVGICON.User} <span className="ms-2">Bucketlist </span>
                        </Link>
                      </div>
                      */}
                      {currentUser && (currentUser.role !== "groupMember" || userIsGroupAdmin) ? (
                        <div
                          className="card-body px-0 py-2"
                          onClick={handleDropdownSelect}
                        >
                          <Link
                            to={"/admin-access"}
                            onClick={handleDropdownSelect}
                            className="dropdown-item ai-icon"
                          >
                            {SVGICON.User}{" "}
                            <span className="ms-2">Admin Options </span>
                          </Link>
                        </div>
                      ) : (
                        <div
                          className="card-body px-0 py-2"
                          onClick={handleDropdownSelect}
                        >
                          <Link
                            to={"/admin-access-member"}
                            onClick={handleDropdownSelect}
                            className="dropdown-item ai-icon"
                          >
                            {SVGICON.User}{" "}
                            <span className="ms-2">Create Group </span>
                          </Link>
                        </div>
                      )}
                      <div className="card-footer px-0 py-2">
                        <LogoutPage />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default UserMenu;
