import React from 'react';
import { Link } from "react-router-dom";
import { FaUntappd, FaCube, FaRegGem, FaClipboardList, FaBullhorn, FaBook } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faMagic } from '@fortawesome/free-solid-svg-icons';

const FooterBar = ({ currentUser, activeTab }) => {
  const iconStyle = {
    fontSize: '1.5rem',
  };
  const labelStyle = {
    fontSize: '0.7rem',
  };
  const getTabStyle = (tabName) => {
    return activeTab === tabName ? { color: '#F0E663' } : {};
  };

  return (
    <div className="footer-bar" style={{ background: '#01152F', border: "2px solid #01152F", }}>
      {currentUser && (currentUser.role === 'globalAdmin' || currentUser.email === 'groupadmin@nyepredictions.com') && (
        <span style={{ color: "#DB8020" }}>Welcome to NYE Predictions</span>
      )}
      {currentUser && (currentUser.role !== 'globalAdmin' && currentUser.email !== 'groupadmin@nyepredictions.com') && (
        <>
          <Link to="/dashboard" className="footer-icon" style={getTabStyle('Home')}>
            <FaUntappd style={iconStyle} />
            <span style={{ ...labelStyle, ...getTabStyle('Home') }}>Home</span>
          </Link>
          <Link to="/announcements" className="footer-icon" style={getTabStyle('Milestones')}>
            <FaBullhorn style={iconStyle} />
            <span style={{ ...labelStyle, ...getTabStyle('Milestones') }}>Announcements</span>
          </Link>
          <Link to="/goals" className="footer-icon" style={getTabStyle('Goals')}>
            <FontAwesomeIcon icon={faBullseye} />
            <span style={{ ...labelStyle, ...getTabStyle('Goals') }}>Goals</span>
          </Link>
          {/*
          <Link to="/bucketlist" className="footer-icon" style={getTabStyle('Bucketlist')}>
            <FaClipboardList style={iconStyle} />
            <span style={{ ...labelStyle, ...getTabStyle('Bucketlist') }}>Bucketlist</span>
          </Link>
          */}
          <Link to="/predictions" className="footer-icon" style={getTabStyle('Predictions')}>
            <FontAwesomeIcon icon={faMagic} />
            <span style={{ ...labelStyle, ...getTabStyle('Predictions') }}>Predictions</span>
          </Link>
          <Link to="/info" className="footer-icon" style={getTabStyle('Info')}>
            <FaBook style={iconStyle} />
            <span style={{ ...labelStyle, ...getTabStyle('Info') }}>Info</span>
          </Link>
        </>
      )}
    </div>
  );  
};

export default FooterBar;
