// PredictionsView.js

import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Form, Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert";
import CountdownDisplay from "./../Dashboard/elements/CountdownDisplay";
import PredictionContent from "./PredictionsViewData"; // Import the new component
import moment from "moment-timezone"; // Add this line for moment
import axios from "axios"; // Import Axios

export default function TaskSummaryBlog() {
  const [loading, setLoading] = useState(true);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const handleUpdateTrigger = () => {
    setUpdateTrigger((prevTrigger) => !prevTrigger);
  };
  
  const currentYear = new Date().getFullYear();
  const [activeTab, setActiveTab] = useState(localStorage.getItem('user_predictions_tab') || "Tab1");
  const [countdown, setCountdown] = useState(null);
  const [flip, setFlip] = useState(false);
  const [presentYear, setPresentYear] = useState(currentYear);
  const [totalCount, setTotalCount] = useState(10);

  const [previousPreviousPredictionsData, setPreviousPreviousPredictionsData] = useState(null);
  const [previousPredictionsData, setPreviousPredictionsData] = useState(null);
  const [currentPredictionsData, setCurrentPredictionsData] = useState(null);
  const [nextPredictionsData, setNextPredictionsData] = useState(null); // Added this line
  const [nextNextPredictionsData, setNextNextPredictionsData] = useState(null); // Added this line

  const [prevPrevIsLocked, setPrevPrevIsLocked] = useState();
  const [prevIsLocked, setPrevIsLocked] = useState();
  const [currIsLocked, setCurrIsLocked] = useState();
  const [nextIsLocked, setNextIsLocked] = useState();
  const [nextNextIsLocked, setNextNextIsLocked] = useState();

  const [prevPrevGameStatus, setPrevPrevGameStatus] = useState();
  const [prevGameStatus, setPrevGameStatus] = useState();
  const [currGameStatus, setCurrGameStatus] = useState();
  const [nextGameStatus, setNextGameStatus] = useState();
  const [nextNextGameStatus, setNextNextGameStatus] = useState();
  
  const [prevPrevAnswerToggle, setPrevPrevAnswerToggle] = useState();
  const [prevAnswerToggle, setPrevAnswerToggle] = useState();
  const [currAnswerToggle, setCurrAnswerToggle] = useState();
  const [nextAnswerToggle, setNextAnswerToggle] = useState();
  const [nextNextAnswerToggle, setNextNextAnswerToggle] = useState();

  const [userTimestamp, setUserTimestamp] = useState(Date.now()); 
  
  
  const groupNameLocal = localStorage.getItem("user_initial_Group");
  const groupName = groupNameLocal;

  const changeBackground = (backgroundOptions) => {
    // Implement the logic for changing the background
  };

  const updateTotalCount = (newCount) => {
    setTotalCount(newCount);
  };

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab); 
    localStorage.setItem('user_predictions_tab', selectedTab);
  };

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });

    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch data from the backend API
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/all-predictions/${groupName}?userTimestamp=${userTimestamp}`);
        const predictionsData = response.data;
        
        // Extract and set data for tabs
        setPreviousPreviousPredictionsData(predictionsData[presentYear - 2]);
        setPreviousPredictionsData(predictionsData[presentYear - 1]);
        setCurrentPredictionsData(predictionsData[presentYear]);
        setNextPredictionsData(predictionsData[presentYear + 1]);
        setNextNextPredictionsData(predictionsData[presentYear + 2]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        // Handle error as needed
        setLoading(false);
      }
    };

    fetchData(); // Fetch data when the component mounts

    // Calculate the countdown
    const calculateCountdown = () => {
      const newYearDate = moment.tz("2024-01-01T00:00:00", "UTC"); // Adjust the year as needed
      const userDateTime = moment.tz(moment(), moment.tz.guess());
      const countdownDuration = moment.duration(newYearDate.diff(userDateTime));

      setCountdown(countdownDuration);
    };

    // Run the countdown calculation initially
    calculateCountdown();

    // Set up a timer to update the countdown every second
    const countdownInterval = setInterval(calculateCountdown, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(countdownInterval);

  }, [groupName, presentYear, userTimestamp, updateTrigger]);

  const storedUserColor = localStorage.getItem("userDetails");
  const storedDetailsColor = JSON.parse(storedUserColor);
  const storedRoleColor = storedDetailsColor.data.role;
  const storedUserId = storedDetailsColor.data.id;
  const bgCustom_Blue = storedRoleColor.includes("Admin") ? "#01152F" : "#01152F";
  
  return (
    <div className="container-fluid" style={{ backgroundColor: bgCustom_Blue }}>
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">          
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={handleTabSelect}
            >
              <Tab eventKey="Tab1" title={`${presentYear + 2}`}>
                <div style={{ height: "88vh", overflowY: "auto" }}>
                  {nextNextPredictionsData ? (
                    <div>
                    <h3 style={{ color: "#F0E663" }}>What do you predict?</h3>
                    <PredictionContent 
                      storedUserId={storedUserId} 
                      predictionsData={nextNextPredictionsData} 
                      presentYear={presentYear + 2} 
                    />
                    </div>
                  ) : (
                    <div>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      {/*
                        <h2 style={{color: '#FFAAAA'}}>The fun is about to begin. Select the year to make your predictions</h2>
                      */}
                      <CountdownDisplay
                        countdown={countdown}
                        setCountdown={setCountdown}
                        flip={flip}
                        setFlip={setFlip}
                      />
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h3 style={{color: '#FF8888'}}>You are a little early to the party, your Group Admin will soon publish your group's questions.</h3>
                      {/*
                      <h3 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h3>
                      <h4 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h4>
                      */}
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Tab2" title={`${presentYear + 1}`}>
                <div style={{ height: "88vh", overflowY: "auto" }}>
                  {nextPredictionsData ? (
                    <div>
                    <h3 style={{ color: "#F0E663" }}>What do you predict?</h3>
                    <PredictionContent storedUserId={storedUserId} predictionsData={nextPredictionsData} presentYear={presentYear + 1} updateTrigger={updateTrigger} setUpdateTrigger={handleUpdateTrigger} />
                    </div>
                  ) : (
                    <div>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <CountdownDisplay
                        countdown={countdown}
                        setCountdown={setCountdown}
                        flip={flip}
                        setFlip={setFlip}
                      />
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h3 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h3>
                      <h4 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h4>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Tab3" title={`${presentYear}`}>
                <div style={{ height: "88vh", overflowY: "auto" }}>
                  {currentPredictionsData ? (
                    <div>
                    <h3 style={{ color: "#F0E663" }}>What do you predict?</h3>
                    <PredictionContent storedUserId={storedUserId}  predictionsData={currentPredictionsData} presentYear={presentYear} updateTrigger={updateTrigger} setUpdateTrigger={handleUpdateTrigger} />
                    </div>
                  ) : (
                    <div>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <CountdownDisplay
                        countdown={countdown}
                        setCountdown={setCountdown}
                        flip={flip}
                        setFlip={setFlip}
                      />
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h3 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h3>
                      <h4 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h4>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Tab4" title={`${presentYear - 1}`}>
                <div style={{ height: "88vh", overflowY: "auto" }}>
                  {previousPredictionsData ? (
                    <div>
                    <h3 style={{ color: "#F0E663" }}>Predictions you made</h3>
                    <PredictionContent storedUserId={storedUserId}  predictionsData={previousPredictionsData} presentYear={presentYear-1} updateTrigger={updateTrigger} setUpdateTrigger={handleUpdateTrigger}  />
                    </div>
                  ) : (
                    <div>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <CountdownDisplay
                        countdown={countdown}
                        setCountdown={setCountdown}
                        flip={flip}
                        setFlip={setFlip}
                      />
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h3 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h3>
                      <h4 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h4>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="Tab5" title={`${presentYear - 2}`}>
                <div style={{ height: "88vh", overflowY: "auto" }}>
                  {previousPreviousPredictionsData ? (
                    <div>
                    <h3 style={{ color: "#F0E663" }}>Predictions you made</h3>
                    <PredictionContent storedUserId={storedUserId}  predictionsData={previousPreviousPredictionsData} presentYear={presentYear-2} updateTrigger={updateTrigger} setUpdateTrigger={handleUpdateTrigger}  />
                    </div>
                  ) : (
                    <div>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <CountdownDisplay
                        countdown={countdown}
                        setCountdown={setCountdown}
                        flip={flip}
                        setFlip={setFlip}
                      />
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h3 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h3>
                      <h4 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h4>
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
      <h1>&nbsp;</h1>
    </div>
  );
}
