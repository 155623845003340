import AWS from "aws-sdk";

export const uploadFileToS3 = (file, callback, fileName) => {
  const parts = file.name.split(".");
  const fileExtension = parts[parts.length - 1];
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  });
  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
  const REGION = process.env.REACT_APP_AWS_REGION_NAME;

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: `images/${fileName}.${fileExtension}`,
  };

  myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {})
    .send((err, data) => {
      if (err) console.log(err);
      callback(
        `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/images/${fileName}.${fileExtension}`
      );
    });
};
