// CountdownDisplay.js
import React from 'react';
import CountdownItem from './Countdown';
import moment from 'moment-timezone';

const CountdownDisplay = ({ countdown, setCountdown, flip, setFlip }) => {
  const today = moment();
  const newYearDate = moment().add(1, 'year').startOf('year');
  const monthsRemaining = newYearDate.diff(today, 'months');

  return (
    <div className="col-xl-12 wid-100 d-flex align-items-center justify-content-center">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {monthsRemaining >= 6 && countdown && (
          <div style={{ display: 'flex' }}>
            <CountdownItem
              label="Months"
              value={countdown.months()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Weeks"
              value={countdown.weeks()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Days"
              value={countdown.days()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Hours"
              value={countdown.hours()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
          </div>
        )}
        {(monthsRemaining >= 2 && monthsRemaining < 6) && countdown && (
          <div style={{ display: 'flex' }}>
            <CountdownItem
              label="Weeks"
              value={countdown.weeks()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Days"
              value={countdown.days()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Hours"
              value={countdown.hours()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
            <CountdownItem
              label="Minutes"
              value={countdown.minutes()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
          </div>
        )}
        {monthsRemaining < 2 && countdown && (
          <div style={{ display: 'flex' }}>
            <CountdownItem
              label="Days"
              value={countdown.days()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
              flip={flip}
              setFlip={setFlip}
            />
            <CountdownItem
              label="Hours"
              value={countdown.hours()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
              flip={flip}
              setFlip={setFlip}
            />
            <CountdownItem
              label="Minutes"
              value={countdown.minutes()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
              flip={flip}
              setFlip={setFlip}
            />
            <CountdownItem
              label="Seconds"
              value={countdown.seconds()}
              backgroundColor="#8B2020"
              setCountdown={setCountdown}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CountdownDisplay;
