import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GroupList from '../../../components/bootstrap/PredictionsListGroup';
import Swal from 'sweetalert2';
import axios from 'axios';

const DeleteGroup = ({ userAdminGroups, userIsGroupAdmin, userGroups }) => {
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [allGroups, setAllGroups] = useState([]);
  const navigate = useNavigate();

  const handleGroupSelect = (groupId) => {
      // Handle the selected group ID here
      setSelectedGroupId(groupId);
    };
  
    const handleSendInvitation = async () => {
      // Check if a group is selected
      if (!selectedGroupId) {
          // Show a Swal indicating that a group must be selected
          Swal.fire('', 'Please select a group.', 'error');
          return;
      }
      if (selectedGroupId) {
        Swal.fire({
          text: 'Delete this group?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, I am sure',
          cancelButtonText: 'Cancel',
        }).then(async (result) => {
          if (result.isConfirmed) { 
            try {
                const tokenDetailsString = localStorage.getItem('userDetails');
                const tokenDetails = JSON.parse(tokenDetailsString);
                const token = tokenDetails.token;
                // Call your API here using fetch or axios
                const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/delete-group`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                    groupId: selectedGroupId,
                    }),
                });
                
                if (response.ok) {
                  // await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/member-connect`, {
                  //   method: 'POST',
                  //   headers: {
                  //     'Content-Type': 'application/json',
                  //   },
                  //   body: JSON.stringify({
                  //     userId:tokenDetails.data.id,
                  //     groupId: 'abcdef',
                  //     mode: '014'
                  //   }),
                  // });
                    // Show a success Swal or perform the desired action
                    Swal.fire('Done!', 'Group has been removed.', 'success').then((result) => {
                      // If the user clicks "OK", result.value will be true
                      if (result.isConfirmed) {
                        // Reload the page after the user clicks "OK"
                        window.location.reload();
                      }
                    });
                } else {
                    // Handle API error and show an error Swal
                    Swal.fire('Oops!', 'Try again please.', 'error');
                }
            } catch (error) {
                console.error('Error sending invitation:', error);
                // Show an error Swal for unexpected errors
                Swal.fire('Oops!', 'Try again please.', 'error');
            }
          }
        });
      }
  };

  useEffect(() => {
    const fetchAllGroups = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		const tokenDetails = JSON.parse(tokenDetailsString);
		const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getAllGroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setAllGroups(response.data.data.groups);

        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchAllGroups();
  }, []);

  return(
        <>
            <div className="container-fluid">
                <div className="row">
                    
                        <div className="card profile-card card-bx">
                            <form className="profile-form">
                                <div className="card-body"> 
                                  <GroupList userAdminGroups={userAdminGroups} userIsGroupAdmin={userIsGroupAdmin}  allGroups={allGroups} userGroups={userGroups} onGroupSelect={handleGroupSelect} />
                                </div> 
                                &nbsp;
                                <div className="row">
                                  <div className="row">
                                      <button type="button" className="btn btn-danger" onClick={handleSendInvitation}>Delete</button>
                                  </div>
                                </div>
                            </form>
                        </div>
                        
                </div>
            </div>
        </>
    )
}
export default DeleteGroup;