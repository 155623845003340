import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from '../../../context/ThemeContext';
import MainPagetitle from '../../layouts/PredictionsPagetitle';
import PredictionsView from '../bootstrap/PredictionsView';
import { useSelector } from 'react-redux';
import FooterBar from './FooterBar';
import bg6 from '../../../images/background/nye-slim-2600.png';

const Home = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { changeBackground } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('Predictions'); // Initialize with the default tab

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
  }, []);

  return (
    /* <div style={{ background: `#fff url(${bg6}) center center / cover no-repeat`, height: "100vh" }}> */
    <div>
      {/* <div className="container-fluid" style={{ height: '440px', backgroundColor: 'rgba(255, 255, 255, 0.55)'}}> */}
      <div className="container-fluid" >
      <MainPagetitle mainTitle="Predictions" pageTitle="Predictions" parentTitle="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 wid-100">
			      <PredictionsView />
          </div>
        </div>		
        <div className="row">
          <div className="col-xl-12 mt-3">
            {" "}
          </div>
        </div>	
        <div className="row">
          <div className="col-xl-12 mt-3">
            {" "}
          </div>
       </div>
      </div>
      <FooterBar activeTab={activeTab} currentUser={currentUser} /> {/* Pass activeTab as a prop */}
      </div>
    </div>
  );
};

export default Home;
 