import React, {useState, useEffect} from 'react'
import { Fragment } from 'react';
import Highlight from "react-highlight";
//import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-scroll";

/// Page Title
import PageTitle from "../../layouts/PageTitle";

/// Images
import img1 from "../../../images/big/img1.jpg";
import img2 from "../../../images/big/img2.jpg";
import img3 from "../../../images/big/img3.jpg";
import img4 from "../../../images/big/img4.jpg";
import img5 from "../../../images/big/img5.jpg";
import img6 from "../../../images/big/img6.jpg";
import img7 from "../../../images/big/img7.jpg";

/// Bootstrap
import { Row, Col, Card, Carousel, Nav, Tab } from "react-bootstrap";

const PredictionPics = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const groupName = localStorage.getItem('user_initial_Group');
  const [activeLink ,setActiveLink] = useState(0);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        if (groupName) {
          const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/group/${groupName}/images`);
          
          if (response.ok) {
            const data = await response.json();
            setImageUrls(data.imageUrls);
          } else {
            console.error('Error fetching image URLs. Server responded with:', response.status);
            // Optionally handle the error, e.g., set a default imageUrls or show a message to the user
          }
        }
      } catch (error) {
        console.error('Error fetching image URLs:', error);
        // Optionally handle the error, e.g., set a default imageUrls or show a message to the user
      }
    };
  
    fetchImageUrls();
  }, [groupName]);
  
  const uploadPics = [...imageUrls];
  

  return (
    <Fragment>
      {/* <PageTitle motherMenu='Bootstrap' activeMenu='Carousel' /> */}
      <div className="container-fluid">
          <div className="element-area">
            <div className="demo-view">
              <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
                <Row>
                  <Col xl={12}>
                    <Tab.Container defaultActiveKey="Preview">
                      <Card name="slides-only" className="dz-card">
                          <Tab.Content>
                            <Tab.Pane eventKey="Preview">
                              <Card.Body className='p-4'>
                              <Carousel style={{ maxHeight: '200px', overflow: 'hidden' }}>
                                  {uploadPics.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                      <div
                                        style={{
                                          height: '200px',
                                          overflow: 'hidden',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <img
                                          src={carousel}
                                          alt=''
                                          className='d-block'
                                          style={{ maxHeight: '100%', maxWidth: '100%' }}
                                        />
                                      </div>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </Card.Body>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Code">
                            <div className="card-body pt-0 p-0 code-area">
                              <pre className="mb-0"><code className="language-html">
                              <Highlight>
                              {`
                              <Carousel>
                                {uploadPics.map((carousel, i) => (
                                  <Carousel.Item key={i}>
                                  <img
                                      src={carousel}
                                      className='d-block w-100'
                                      alt={\`Slide $\{i + 1}\`}
                                    />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                              `}
                                </Highlight>
                              </code>
                            </pre>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Card>
                  </Tab.Container>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PredictionPics;
