import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import GroupList from "./PredictionsListGroup";
import Swal from "sweetalert2";
import { uploadFileToS3 } from "../../../config/config";

export default function TaskSummaryBlog() {
  const [announcementText, setAnnouncementText] = useState("");
  const [announcementList, setAnnouncementList] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [progress, setProgress] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.data) {
      setAnnouncementText(location?.state?.data.description);
      setImageUrl(location?.state?.data.image);
    }
  }, [imageUrl, progress, location]);

  const fetchAnnouncements = async () => {
    try {
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      const currentGroup = localStorage.getItem("user_initial_Group");
      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/announcements/${currentGroup}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAnnouncementList(data);
      } else {
        console.error("Failed to fetch announcements");
      }
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []); // Call fetchAnnouncements on mount

  const handleSaveAnnouncement = async (imageUrl) => {
    try {
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/saveAnnouncement`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: announcementText,
            currentGroup: localStorage.getItem("user_initial_Group"),
            pic: imageUrl,
          }),
        }
      );

      if (response.ok) {
        Swal.fire("Nice!", "Thanks for sharing.", "success").then((result) => {
          if (result.isConfirmed) {
            // Call fetchAnnouncements to update the announcementList
            fetchAnnouncements();
            setAnnouncementText(""); // Clear the input field after successful save
            navigate("/announcements");
          }
        });
      } else {
        console.error("Failed to save announcement");
      }
    } catch (error) {
      console.error("Error while saving announcement:", error);
    }
  };

  const params = useParams();
  const handleUpdateAnnouncement = async (imageUrl) => {
    try {
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      const announcementPayload = {
        text: announcementText,
        currentGroup: localStorage.getItem("user_initial_Group"),
        pic: imageUrl,
        id: params.id,
      };

      if (imageUrl === "") delete announcementPayload["pic"];

      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/updateAnnouncement`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            announcement: { ...announcementPayload },
          }),
        }
      );

      if (response.ok) {
        Swal.fire("Nice!", "Thanks for sharing.", "success").then((result) => {
          if (result.isConfirmed) {
            // Call fetchAnnouncements to update the announcementList
            fetchAnnouncements();
            setAnnouncementText(""); // Clear the input field after successful save
            navigate("/announcements");
          }
        });
      } else {
        console.error("Failed to save announcement");
      }
    } catch (error) {
      console.error("Error while saving announcement:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
                <form className="profile-form">
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #01152F",
                      borderRadius: "10px", 
                    }}
                  >
                    <div>
                      <GroupList />
                    </div>
                    &nbsp;
                    <div>
                      <textarea
                        className="form-control"
                        rows="4"
                        maxLength="200"
                        placeholder="share with your group"
                        value={announcementText}
                        onChange={(e) => setAnnouncementText(e.target.value)}
                      ></textarea>
                    </div>
                    &nbsp;
                    <div className="mt-3">
                      <label htmlFor="fileUpload" className="form-label">
                        <label className="form-label">Add picture</label>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="fileUpload"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                  &nbsp;
                  <div className="row">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        const groupName =
                          localStorage.getItem("user_initial_Group");

                        const fileName = `${groupName.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        )}Announcement/${Date.now()}`;
                        if (imageFile) {
                          uploadFileToS3(
                            imageFile,
                            (data) => {
                              if (params.id) {
                                handleUpdateAnnouncement(data);
                              } else {
                                handleSaveAnnouncement(data);
                              }
                            },
                            fileName
                          );
                        } else {
                          if (params.id) {
                            handleUpdateAnnouncement("");
                          } else {
                            handleSaveAnnouncement("");
                          }
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
}
