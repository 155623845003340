import React, { Fragment, useState } from "react";
import {Link , useNavigate} from 'react-router-dom';

import { Row, Card, Col, ListGroup, Badge, Tab, Nav } from "react-bootstrap";
import axios from 'axios';
  
const PredictionsSelectGroup = ({ userGroups, onHide }) => {
  const updateInitialGroup = async (groupName) => {
    try {
      // Get the token from localStorage
      const tokenDetailsString = localStorage.getItem('userDetails');
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;
      const userId = tokenDetails.data.id;
      
      // Make an API call or perform the necessary logic to update the user's initialGroup
      const response = await axios.patch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/updateInitialGroup`,
        { userId: userId, initialGroup: groupName },  // Pass data directly as the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        // Handle success
        localStorage.setItem('user_initial_Group', groupName);

        window.location.reload();
        
      } else {
        // Handle error
        console.error('Error updating user initialGroup');
      }
    } catch (error) {
      console.error('Error updating user initialGroup:', error);
    }
  };
  
  const nav = useNavigate();
  return (
    <Fragment>
       <div className="container-fluid">
          <div className="">
            <div className="demo-view">
              <div className="container-fluid">
                <Row>
                  <Col xl="12">
                    <Tab.Container defaultActiveKey="Preview">
                      <Card name="with-badges" className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="Preview">
                            <Card.Body>
                              <div className="">
                                <ListGroup>
                                  {userGroups.map((group) => (
                                    <ListGroup.Item className="d-flex flex-column" key={group._id}
                                      onClick={() => {
                                        onHide();
                                        updateInitialGroup(group.groupName);
                                      }}>
                                      <div>
                                        <h6>{group.groupName}</h6>
                                      </div>
                                      <div className="bootstrap-badge">
                                        <Badge as={Link}
                                          bg="badge-rounded"
                                          className='badge-outline-primary'
                                        >
                                          {group.memberCount} members
                                        </Badge>
                                      </div>
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>

                              </div>
                            </Card.Body>
                          </Tab.Pane>
                        </Tab.Content>    
                      </Card>
                    </Tab.Container>  
                  </Col>
                </Row>             
              </div>  
            </div> 
          </div>  
        </div>  
    </Fragment>
  );
};

export default PredictionsSelectGroup;
