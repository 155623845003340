// UserActions.js

import { SET_CURRENT_USER, LOGOUT_USER } from "../actions/UserActionTypes";

const setCurrentUser = (userDetails) => {
  // Retrieve user details from localStorage
  const storedUserDetails = localStorage.getItem("userDetails");

  // Parse the stored user details if available
  let storedData = null;
  if (storedUserDetails) {
    try {
      storedData = JSON.parse(storedUserDetails).data;
    } catch (error) {
      storedData = null;
    }
  }

  // Check if the stored email matches the provided email
  if (storedData && storedData.email === userDetails.email) {
    // Update currentUser state with additional details from localStorage
    const updatedUser = {
      email: userDetails.email,
      names: storedData.names, // Add name from localStorage
      role: storedData.role, // Add role from localStorage
      accessToken: storedData.accessToken, // Add accessToken from localStorage
      pic: storedData.pic
    };

    return {
      type: SET_CURRENT_USER,
      payload: updatedUser,
    };
  } else {
    // If email doesn't match or no stored data, update with the provided details
    return {
      type: SET_CURRENT_USER,
      payload: userDetails,
    };
  }
};

const logoutUser = () => {
  // Clear localStorage on logout
  localStorage.removeItem("userDetails");

  return {
    type: LOGOUT_USER,
  };
};

export { setCurrentUser, logoutUser };
