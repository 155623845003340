import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { ThemeContext } from '../../../context/ThemeContext';
import { useSelector, useDispatch } from 'react-redux';
import MainPagetitle from '../../layouts/PredictionsPagetitle';
import FooterBar from '../../components/Dashboard/FooterBar';
import DashboardPics from '../../components/bootstrap/DashboardPics';
import AddNewGroup from '../../components/AppsMenu/AppProfile/AppAddNewGroup';
import AddNewMember from '../../components/AppsMenu/AppProfile/AddNewMember';
import AddNewGroupAdmin from '../../components/AppsMenu/AppProfile/AddNewGroupAdmin';
import AddNewGlobalAdmin from '../../components/AppsMenu/AppProfile/AddNewGlobalAdmin';
import ChangeUserPassword from '../../components/AppsMenu/AppProfile/ChangeUserPassword';
import RemoveGroupMember from '../../components/AppsMenu/AppProfile/RemoveGroupMember';
import DeleteGroup from '../../components/AppsMenu/AppProfile/DeleteGroup';
import QuestionTemplate from '../../components/AppsMenu/AppProfile/QuestionTemplate';
import axios from 'axios';
import bg6 from '../../../images/background/nye-slim-2600.png';

// Import the action to fetch user groups
import { getUserGroupsAction } from '../../../store/actions/UserActions';

const Home = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { changeBackground } = useContext(ThemeContext);
  const [allGroups, setAllGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [userAdminGroups, setUserAdminGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState('Admin'); // Initialize with the default tab
  const [userIsGroupAdmin, setUserIsGroupAdmin] = useState('?');
  const dispatch = useDispatch();
  
  const storedUserDetails = localStorage.getItem("userDetails");
  const storedDataDetails = JSON.parse(storedUserDetails).data;
  const storedRole = storedDataDetails.role;
  const storedUserId = storedDataDetails.id;

  useEffect(() => {
    
    const fetchUserGroups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroupsAdmin/${storedUserId}`);
        const userGroupsInfo = response.data.data.userGroups;
		setUserAdminGroups(response.data.data.userGroups);
        
        // Check if the user has the role of 'groupAdmin' in any group
        const hasGroupAdminRole = userGroupsInfo.some(group => group.role === 'groupAdmin');

		if (hasGroupAdminRole) {
			setUserIsGroupAdmin('groupAdmin');
			//storedRole = userIsGroupAdmin;
		} else{
			setUserIsGroupAdmin(storedRole);
		}
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    // Fetch user groups when the component mounts
    fetchUserGroups();
  },[currentUser.id]);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		const tokenDetails = JSON.parse(tokenDetailsString);
		const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setUserGroups(response.data.data.groups);

        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchUserGroups();
  }, []); // Dependency array ensures the effect runs only when the component mounts

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		const tokenDetails = JSON.parse(tokenDetailsString);
		const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUsers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setUsers(response.data.data.users);

        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []); // Dependency array ensures the effect runs only when the component mounts

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
  }, []);

  
  useEffect(() => {
    const fetchAllGroups = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		const tokenDetails = JSON.parse(tokenDetailsString);
		const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getAllGroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setAllGroups(response.data.data.groups);

        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchAllGroups();
  }, []); // Dependency array ensures the effect runs only when the component mounts
  
  return (
    <div style={{ backgroundColor: '#01152F' }}>
	<div>
	<div className="page-titles">
	  <ol className="breadcrumb">
		<li>
		  <h5 className="bc-title">Admin Access</h5>
		</li>
		<li className="breadcrumb-item"></li>
	  </ol>
	  <Link to="/dashboard">
		<button
		  variant="primary"
		  className="btn btn-primary dz-xs-flex m-r5"
		  style={{ backgroundColor: '#6B8DEF', width: '80px', height: '28px', margin: '0px', padding: '0px' }}
		>
		  Close
		</button>
	  </Link>
	</div>
      <div className="container-fluid">

        <div className="row">
          <div className="col-xl-12 wid-100">
							<div className='col-xl-12'>
								<div className="card">
									<div className="card-body profile-accordion pb-0">
										<Accordion className="accordion" id="accordionExample2" defaultActiveKey={[]}>
											<Accordion.Item className="accordion-item">
												<Accordion.Header as="h2" className="accordion-header" id="headingOne2">												  
													Create a new group												  
												</Accordion.Header>
												<Accordion.Body id="collapseOneM" className="accordion-collapse collapse show">	
                          							<AddNewGroup />
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</div>
							</div>
          </div>
        </div>	

        <div className="row">
        <div className="col-xl-12 mt-3">
          {" "}
        </div>
      </div>	
      <div className="row">
        <div className="col-xl-12 mt-3">
          {" "}
        </div>
      </div>
      </div>
      <FooterBar activeTab={activeTab} currentUser={currentUser} /> {/* Pass activeTab as a prop */}
	  </div>
    </div>
  );
};

export default Home;
