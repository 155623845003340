import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
  } from '../actions/AuthActions';
  
  import { setCurrentUser } from '../actions/UserActions';
  
  const initialState = {
    auth: {
      email: '',
      accessToken: '',
      expiresIn: '',
      idToken: '',
      //localId: '',
      //refreshToken: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
  };
  
  export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
      return {
        ...state,
        auth: action.payload,
        errorMessage: '',
        successMessage: 'Signup Successfully Completed',
        showLoading: false,
      };
    }
  
    if (action.type === LOGIN_CONFIRMED_ACTION) {
      const storageDetails = localStorage.getItem('userDetails');
      let user_email = '';
      
      if (storageDetails) {
        try {
            const storageData = JSON.parse(storageDetails);
            if (storageData) {
                if (storageData.data) {
                    if (storageData.data.email && storageData.data.email.length > 1) {
                    user_email = storageData.data.email;
                    }
                }
                if (storageData.email && storageData.email.length > 1) {
                    user_email = storageData.data.email;
                }
                setCurrentUser({ 
                    email: user_email,
                    names: storageData.names, // Add name from localStorage
                    role: storageData.role, // Add role from localStorage
                    accessToken: storageData.accessToken, // Add accessToken from localStorage
                });
            }
        } catch (error) {
            user_email = '';
        }
      }
      
      return {
        ...state,
        auth: {
          ...state.auth,
          idToken: 'Predictions',
          accessToken: 'Predictions',
          email: user_email,
        },
        errorMessage: '',
        successMessage: 'Login Successfully Completed',
        showLoading: false,
      };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                accessToken: '',
                expiresIn: '',
                idToken: '',
                //localId: '',
                //refreshToken: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
