import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";

const PredictionsListGroup = ({ userAdminGroups, userIsGroupAdmin, allGroups, userGroups, onGroupSelect }) => {
  const nav = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState('');

  const storedUser = localStorage.getItem("userDetails");
  const storedDetails = JSON.parse(storedUser);
  const storedRole = storedDetails.data.role;

  // Create listItem from the userGroups API response if it's defined
  let listItem = [];
  if(storedRole === "groupAdmin" || storedRole === "globalAdmin"){
    listItem = allGroups ? allGroups.map(group => ({
      id: group.id,
      name: group.groupName,
      count: group.memberCount
    })) : [];
  } else {
    if(userAdminGroups && userAdminGroups !== null){
      listItem = userAdminGroups
        .filter(adminGroup => userGroups.some(userGroup => userGroup._id === adminGroup.id))
        .map(adminGroup => {
          const userGroup = userGroups.find(group => group._id === adminGroup.id);
          return {
            id: userGroup._id,
            name: userGroup.groupName,
            count: userGroup.memberCount
          };
        });
    }
  }

  const handleGroupSelectPick = (groupId) => {
    // console.log("Selected Group ID:", groupId);
    setSelectedGroup(groupId);
    // Call the callback function with the selected group ID
    onGroupSelect(groupId);
  };

  const myUiLabel = listItem.length === 0 ? "" : "Select a group";

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <label className="form-label">{myUiLabel}</label>
          {listItem.length !== 0 && (
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  backgroundColor: 'gray',
                  width: '270px',
                  fontSize: '16px'
                }}
              >
                {listItem.find(item => item.id === selectedGroup)?.name || 'Select Group'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {listItem.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={() => handleGroupSelectPick(item.id)}
                    active={selectedGroup === item.id}
                    style={{ backgroundColor: 'white' }}
                  >
                    <div>
                      <h4>{item.name}</h4>
                      <p>Member Count: {item.count}</p>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PredictionsListGroup;
