import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Column from "../Dashboard/elements/DataGoals";
import { Row, Col } from "react-bootstrap";
import moment from "moment-timezone"; // Add this line for moment
import Swal from "sweetalert2";
import axios from "axios";

function HeadOne({ bgcolor }) {
  return ""; // Placeholder, you can modify this as needed
}

export default function TaskSummaryBlog() {
  const [currentGameGoals, setCurrentGameGoals] = useState('?');
  const [templateShown, setTemplateShown] = useState(0);
  const [userTimestamp, setUserTimestamp] = useState(Date.now()); 
  const currentYear = new Date().getFullYear()+1;

  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sliderValues, setSliderValues] = useState({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({}); // Import setSelectedDropdownValues
  const [goalsList, setGoalsList] = useState({
    tasks: {},
    columns: {},
    columnOrder: [],
  });
  const [showForm, setShowForm] = useState(false); // State to manage the visibility of the form
  const [newItemDescription, setNewItemDescription] = useState(""); // State to store the new bucket list item description
  const [selectedOption, setSelectedOption] = useState("Option1"); // Default selected option

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const updateTotalCount = (updatedCount) => {
    setTotalCount(updatedCount);
  };

  const initializeDropdownAndSliderValues = () => {
    const initialDropdownValues = {};
    const initialSliderValues = {};

    // Convert goalsList.tasks to an array
    const tasksArray = Object.values(goalsList.tasks);

    // Assuming that the API response is an array of tasks
    for (const task of tasksArray) {
      initialDropdownValues[task.id] = task.select;
      initialSliderValues[task.id] = task.score;
    }

    setSliderValues(initialSliderValues);
    setSelectedDropdownValues(initialDropdownValues);
  };

  const fetchGoals = async () => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    let retries = 0;
    const maxRetries = 5; // Adjust as needed

    while (retries < maxRetries) {
      try {
        let groupName = localStorage.getItem("user_initial_Group");

        // Retry only if groupName is not set
        if (!groupName) {
          retries++;
          console.log(`Retrying fetchGoals - Attempt ${retries}`);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
          continue;
        }

        const currentDate = new Date();
        const currentYearVal = currentDate.getFullYear();

        const encodedPathGroupName = encodeURIComponent(groupName);
        const response = await axios.get(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/goals/${encodedPathGroupName}/${currentYearVal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const transformedData = transformResponse(response.data);
          setGoalsList(transformedData);
          break; // Break out of the loop if successful
        } else {
          console.error("Failed to fetch goals");
        }
      } catch (error) {
        console.error("Error fetching goals:", error);
        retries++;
        console.log(`Retrying fetchGoals - Attempt ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      }
    }
  };

  const transformResponse = (data) => {
    // Your transformation logic here
    const tasks = {};
    const columns = {
      "column-1": {
        id: "column-1",
        title: <HeadOne bgcolor="primary" />,
        taskIds: [],
      },
    };

    data.slice(0, 5).forEach((item, index) => {
      const taskId = index + 1;
      tasks[taskId] = {
        mode: item.src,
        id: item._id,
        content: item.item,
        answer: "",
        select: item.status,
        score: item.score,
        created: item.addedAt,
        updated: item.statusChangedAt,
        canScore: item.canScore,
      };
      columns["column-1"].taskIds.push(taskId);
    });

    const columnOrder = ["column-1"];
    setTemplateShown(0);

    return {
      tasks,
      columns,
      columnOrder,
    };
  };

  useEffect(() => {
    fetchGoals();
    const intervalId = setInterval(() => {
      fetchGoals();
    }, process.env.REACT_APP_REFRESH_RATE);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (goalsList && goalsList.columnOrder.length > 0) {
      initializeDropdownAndSliderValues();
    }
  }, [goalsList]);

  useEffect(() => {}, [goalsList]);

  const handleAddYoursClick = () => {
    
    const tasksArray = Object.values(goalsList.tasks);
    // Assuming that the API response is an array of tasks
    let templateEntryFound = 0;
    for (const task of tasksArray) {
      if(task.mode === "template"){
        templateEntryFound = 1;
        setTemplateShown(1);
      }
    }
    
    const totalGoals = Object.keys(goalsList.tasks).length;
    if(totalGoals > 4 && templateEntryFound === 0){
      Swal.fire("Sorry!", 'Goals annual max limit reached', "warning")
    } else{
      setShowForm(true);
    }
  };

  const handleSaveClick = async () => {
    try {
      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      // Make the API call to update the MongoDB document
      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/addNewGoalItem`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            groupName: localStorage.getItem("user_initial_Group"),
            item: newItemDescription,
          }),
        }
      );

      if (response.ok) {
        Swal.fire("Awesome!", "Goal added.", "success").then((result) => {
          if (result.isConfirmed) {
            setNewItemDescription("");
            setShowForm(false);
            fetchGoals();
          }
        });
      } else {
        console.error("Failed to save goal");
      }
    } catch (error) {
      console.error("Error while saving announcement:", error);
    }
  };

  const handleCancelClick = () => {
    // Reset the form and hide it
    setNewItemDescription("");
    setShowForm(false);
  };

  const options = [
    "Life",
    "Health",
    "Education",
    "Professional",
    "Travel",
    "Other",
  ];

  const fetchRoleData = async () => {
    try {
      const isGameOpen = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/all-predictions/${localStorage.getItem("user_initial_Group")}?userTimestamp=${userTimestamp}`);
      const gameOpenCheck = isGameOpen.data;
      
      // Assuming gameOpenCheck is the object with years as keys
      const years = Object.keys(gameOpenCheck);
  
      for (const year of years) {
        const currentGameStatus = gameOpenCheck[currentYear][0]?.currentPlay; // Assuming there is at least one item in the array
        setCurrentGameGoals(currentGameStatus);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  fetchRoleData();
  
  return (
    <div className="container-fluid" style={{ backgroundColor: "#01152F" }}>
      {/*
      [{currentYear}][{currentGameGoals}]
      */}
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
                <div className="profile-form">
                  {/* Conditionally render the form based on showForm state */}
                  {showForm ? (
                    <>
                      <button
                        onClick={handleSaveClick}
                        className="btn btn-primary dz-xs-flex"
                      >
                        Save
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        onClick={handleCancelClick}
                        className="btn btn-secondary dz-xs-flex"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <div>
                      {goalsList.columnOrder && goalsList.columnOrder.length > 0 && (
                        <button
                          onClick={handleAddYoursClick}
                          className="btn btn-primary dz-xs-flex"
                          style={{
                            color: "black",
                            backgroundColor: "#FFF8AF",
                            width: "120px",
                            height: "28px",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          Add Yours
                        </button>
                      )}
                    </div>
                  )}
                  <p> </p>
                  <div style={{ backgroundColor: "#01152F" }}>
                    {/* Placeholder for the new bucket list form */}
                    {/* You can customize this section based on your form requirements */}
                    {showForm && (
                      <form>
                        <div className="form-row align-items-center">
                          <div className="mt-3">
                            <div className="form-group d-flex">
                              <input
                                className="form-control"
                                type="text"
                                value={newItemDescription}
                                onChange={(e) =>
                                  setNewItemDescription(e.target.value)
                                }
                                placeholder="Enter your goal item"
                              />
                            </div>
                          </div>
                        </div>
                        &nbsp;
                      </form>
                    )}
                  </div>
                  <p> </p>
                </div>
              </Col>
              <Col xl={12}>
                <div
                  style={{ height: "76vh",backgroundColor: "#01152F" }}
                  id="DZ_W_TimeLine"
                  className="widget-timeline dz-scroll height370 ps--active-y"
                >
                  {goalsList.columnOrder && goalsList.columnOrder.length > 0 ? (
                    goalsList.columnOrder.map((columnId, index) => {
                      const column = goalsList.columns[columnId];
                      const tasks = column.taskIds.map((taskId) => goalsList.tasks[taskId]);

                      // Pass setSelectedDropdownValues to Column component
                      return (
                        <Column
                          key={column.id}
                          column={column}
                          tasks={tasks}
                          updateTotalCount={updateTotalCount}
                          sliderValuesProp={sliderValues}
                          selectedDropdownValues={selectedDropdownValues}
                          setSelectedDropdownValues={setSelectedDropdownValues} // Pass the function
                          currentGameGoals={currentGameGoals}
                        />
                      );
                    })
                  ) : (
                    <div>
                      <h4 style={{ color: '#DDDDDD'}}>
                      Your Group Admin will be publishing up to 5 goals for your group! Stay tuned.
                      </h4>
                      <p>You can also add your own goals you want to achieve in the New Year.</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
}
