import React, { useState, useEffect } from "react";
import { Modal, Carousel, Button } from "react-bootstrap";
import { IMAGES } from "../../constant/theme";
import { Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const MilestonesView = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [announcementList, setAnnouncementList] = useState([]);

  const storedUser = localStorage.getItem("userDetails");
  const storedDetails = JSON.parse(storedUser);
  const memberId = storedDetails.data.id;

  const iconStyle = {
    fontSize: "1.2rem",
    color: "#56789A",
  };
  const labelStyle = {
    fontSize: "0.9rem",
  };
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    // Add any other styles you need for the container
  };

  const nav = useNavigate();

  const handleEditClick = (item) => {
    // Add your Swal (SweetAlert) logic here
    nav(`/add-announcement/${item.id}`, { state: { data: item } });
  };

  const handleDeleteClick = async (id) => {
    swal({
      text: "Delete this announcement?",
      icon: "warning",
      buttons: ["Cancel", "Continue"],
      dangerMode: true,
    }).then(async (confirmed) => {
      if (confirmed) {
        await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/deleteAnnouncement/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              groupName: localStorage.getItem("user_initial_Group"),
            }),
          }
        );
        fetchAnnouncements();
      }
    });
  };

  const fetchAnnouncements = async () => {
    try {
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      const groupName = localStorage.getItem("user_initial_Group");
      const response = await axios.get(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/announcements/${groupName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.length > 0) {
          const filterData = response.data.filter(
            (item) => item !== null && item
          );
          setAnnouncementList(filterData);
        }
      } else {
        console.error("Failed to fetch announcements");
      }
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
    const intervalId = setInterval(
      fetchAnnouncements,
      process.env.REACT_APP_REFRESH_RATE
    );

    return () => clearInterval(intervalId);
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const carouselItems = announcementList
    .filter((item) => item?.image)
    .map((item, index) => (
      <Carousel.Item key={index}>
        <img
          className="d-block w-100"
          src={item?.image}
          alt={`Image ${index + 1}`}
        />
        <Carousel.Caption style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          <h3>{item.title}</h3>
          <p>{item.description}</p>
        </Carousel.Caption>
      </Carousel.Item>
    ));

  return (
    <div className="container-fluid">
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0" style={{ backgroundColor: "#01152F"}}>
            <Row>
              <Col xl={12}>
                <div
                  className="widget-timeline dz-scroll height370 ps--active-y"
                  style={{ 
                    height: "90vh",
                    backgroundColor: "#01152F"
                   }}
                >
                  {announcementList.length > 0 ? (
                    <ul className="timeline">
                      {announcementList.map((item, index) => (
                        <li key={index}>
                          <div
                            className={`timeline-badge warning`}
                            style={{ 
                              backgroundColor: "#FFFFFF",
                              border: "1.5px solid #F1D34F",
                            }}
                          ></div>
                          <div
                            className="timeline-panel text-muted d-flex justify-content-between align-items-center"
                            style={{
                              backgroundColor: "#FEF1AF",
                              border: "2px solid #F1D34F",
                            }}
                          >
                            <div
                              style={{ whiteSpace: "normal", color: "#345678" }}
                            >
                              <div style={containerStyle}>
                                <span style={labelStyle}>{item.time} </span>
                                {item.createdBy === memberId &&
                                  !item.system && (
                                    <>
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleEditClick(item)}
                                        // onClick={()=>console.log(item)}
                                      >
                                        <FaEdit style={iconStyle} />
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteClick(item.id)
                                        }
                                        // onClick={()=>console.log(item)}
                                      >
                                        <FaTrash style={iconStyle} />
                                      </span>
                                    </>
                                  )}
                              </div>
                              <h6 className="mb-0">
                                {item.description.includes('has joined the group') ? (
                                  <div>
                                    Group Announcement
                                  </div>
                                ) : (
                                  <div>
                                    {item.title}
                                  </div>
                                )}
                              </h6>
                              {item.description && (
                                <p
                                  className="mb-0"
                                  style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {item.description}
                                </p>
                              )}
                            </div>
                            <div>
                              {item.image && (
                                <img
                                  src={item.image}
                                  alt={`Image ${index + 1}`}
                                  style={{
                                    maxWidth: "50px",
                                    maxHeight: "50px",
                                    cursor: "pointer",
                                    borderRadius: "15%",
                                  }}
                                  onClick={() => openModal(item.image)}
                                />
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ backgroundColor: "#01152F"}}>
                      <h4 style={{ color: '#DDDDDD'}}>
                        Your group is quiet...want to be the first to say{" "}
                        <em>Hello</em>?
                      </h4>
                      <p>No announcements as yet</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>

      {/* Modal for Image Carousel */}
      <Modal show={modalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Member Pictures</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>{carouselItems}</Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MilestonesView;
