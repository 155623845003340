import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Accordion } from 'react-bootstrap';
import { ThemeContext } from '../../../context/ThemeContext';
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import AnnouncementNew from '../../components/bootstrap/AnnouncementNew';
import FooterBar from '../../components/Dashboard/FooterBar';
import { useSelector } from 'react-redux';
import bg6 from '../../../images/background/nye-slim-2600.png';

const AddAnnouncement = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { changeBackground } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('Admin'); // Initialize with the default tab

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
  }, []);

  return (
    /* <div style={{ background: `#fff url(${bg6}) center center / cover no-repeat`, height: "100vh" }}> */
    <div>
    <div className="container-fluid" style={{ height: '440px' }}>
    <div className="page-titles">
            <ol className="breadcrumb">
            <li>
            <h5 className="bc-title">Your update</h5>
            </li>
            <li className="breadcrumb-item"></li>
        </ol>
        <Link to="/announcements">
        <button
        variant="primary"
        className="btn btn-primary dz-xs-flex m-r5"
        style={{ backgroundColor: '#6B8DEF', width: '80px', height: '28px', margin: '0px', padding: '0px' }}
        >
        Close
        </button>
        </Link>
    </div>
    
    <div className="container-fluid">
        <div className="row">
            <div className="col-xl-12 wid-100">
                <AnnouncementNew />
            </div>
        </div>
        <div className="row">
            <div className="col-xl-12 mt-3">
            {" "}
            </div>
        </div>	
        <div className="row">
            <div className="col-xl-12 mt-3">
                {" "}
            </div>
        </div>
    </div>
      <FooterBar activeTab={activeTab} currentUser={currentUser} /> {/* Pass activeTab as a prop */}
      </div>
    </div>
  );
};

export default AddAnnouncement;
