import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GroupList from '../components/bootstrap/PredictionsSelectGroup';
import { Offcanvas } from 'react-bootstrap';
import { FaChevronCircleDown } from 'react-icons/fa';
import axios from 'axios';

const MainPagetitle = ({ mainTitle }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showCGButton, setShowCGButton] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Check the condition here to determine whether to show the button
    const shouldShowButton = !location.pathname.includes('/admin-access');
    setShowCGButton(shouldShowButton);
  }, [location]); // Re-run the effect when location changes

  useEffect(() => {
    // Close the Offcanvas when the component is re-rendered
    handleClose();
  }, [showCGButton]); // Re-run the effect when showCGButton changes

  const storedUser = localStorage.getItem('userDetails');
  const storedDetails = JSON.parse(storedUser);
  const storedRole = storedDetails.data.role;

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		    const tokenDetails = JSON.parse(tokenDetailsString);
		    const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setUserGroups(response.data.data.groups);
        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchUserGroups();
  }, []); 

  
  useEffect(() => {
    const fetchAllGroups = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		const tokenDetails = JSON.parse(tokenDetailsString);
		const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getAllGroups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Assuming the API response contains an array of groups
        setAllGroups(response.data.data.groups);

        // You can perform additional actions with the response data if needed
      } catch (error) {
        console.error('Error fetching user groups:', error);
      }
    };

    fetchAllGroups();
  }, []);

  const isPredictionsPage = location.pathname.includes('/predictions');

  return (
    <>
      <div className="page-titles" >
      {(storedRole === "globalAdmin" || storedRole === "groupAdmin") && (
        <div>
          <strong>Welcome to NYE Predictions</strong>
        </div>
      )}
        {(storedRole !== "globalAdmin" && storedRole !== "groupAdmin") && (
          <div>
        <ol className="breadcrumb">
          <li>
            <h5 className="bc-title">{mainTitle}</h5>
          </li>
          <li className="breadcrumb-item"></li>
        </ol>
        </div>
        )}
        {userGroups && userGroups.length > 0 && (
        <>
          {/* Set default group */}
          {(() => {
            const defGroup = userGroups[0].initialGroup || userGroups[0].groupName;
            localStorage.setItem('user_initial_Group', defGroup);
          })()}
          {showCGButton && (storedRole !== "globalAdmin" && storedRole !== "groupAdmin") && window.location.pathname !== "/info" && (
            <button
              variant="primary"
              onClick={() => handleShow()}
              className="btn btn-primary dz-xs-flex m-r5"
              style={{ backgroundColor: '#78CfB', width: '160px', height: '28px', margin: '0px', padding: '0px' }}
            > 
              <FaChevronCircleDown style={{ marginRight: '5px' }} />
              {userGroups[0].initialGroup || userGroups[0].groupName}
            </button>
          )}
        </>
        )}
      </div>
  
      <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-end customeoff">
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal1">
            <FaChevronCircleDown style={{ marginRight: '5px' }} />All Groups
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            onClick={() => handleClose()}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <GroupList userGroups={userGroups} onHide={handleClose} />
        </div>
      </Offcanvas>
    </>
  );
};

export default MainPagetitle;
