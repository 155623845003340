import React, { useContext, useEffect, useState, useRef } from 'react';

import { ThemeContext } from '../../../context/ThemeContext';
import MainPagetitle from '../../layouts/PredictionsPagetitle';
import BucketlistView from '../bootstrap/BucketlistView';
import FooterBar from './FooterBar';
import { useSelector } from 'react-redux';
import bg6 from '../../../images/background/nye-slim-2600.png';

const Home = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
	const task = useRef();
  const { changeBackground } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('Bucketlist'); // Initialize with the default tab

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
  }, []);

  return (
    <div style={{ background: `#fff url(${bg6}) center center / cover no-repeat`, height: "100vh" }}>
    <div className="container-fluid" style={{ height: '440px' }}>
      <MainPagetitle mainTitle="Bucketlist" pageTitle="Bucketlist" parentTitle="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 wid-100">
			      <BucketlistView />
          </div>
        </div>		
        <div className="row">
          <div className="col-xl-12 mt-3">
            {" "}
          </div>
        </div>	
        <div className="row">
          <div className="col-xl-12 mt-3">
            {" "}
          </div>
       </div>
      </div>
      <FooterBar activeTab={activeTab} currentUser={currentUser} /> {/* Pass activeTab as a prop */}
      </div>
    </div>
  );
};

export default Home;
 