import React, { useState, useEffect } from "react";

const HistoryPredictionsView = ({ year, predictions }) => {
  const [questions, setQuestions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (predictions && predictions.predictions) {
      const questions = predictions.predictions.map((prediction, index) => {
        return {
          text: prediction.text,
          // Add more fields as needed
        };
      });

      setQuestions(questions);
      setTotalCount(questions.length);
    }
  }, [predictions]); // Include in the dependency array

  return (
    <div className="col">
      <div className="">
        <div className="">
          <div className="">
            {/* Render questions directly */}
            <ul>
              {questions.map((question, index) => (
                <div className="sub-card p-0">
                  <div className="task-card-data">
                    <div className="">
                      <div>
                        <p>{question.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPredictionsView;
