import React, { useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Routes, Route, Outlet } from "react-router-dom";

/// CSS
import "./index.css";

/// Layout
import Nav from "./layouts/nav";

// Predictions
import Home from "./components/Dashboard/Home";
import MemberProfile from "./components/AppsMenu/AppProfile/PredictionsMemberProfile";
import PredictionsTaskSummary from "./components/Dashboard/PredictionsTaskSummary";
import GoalsTaskSummary from "./components/Dashboard/GoalsTaskSummary";
import BucketlistTaskSummary from "./components/Dashboard/BucketlistTaskSummary";
import AnnouncementsTaskSummary from "./components/Dashboard/AnnouncementsTaskSummary";
import AdminAccess from "./components/Dashboard/AdminAccess";
import AdminAccessMember from "./components/Dashboard/AdminAccessMember";
import AddAnnouncement from "./components/Dashboard/AddAnnouncement";
import Info from "./components/Dashboard/InfoTaskSummary";
 
/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import { ThemeContext } from "../context/ThemeContext";
const allroutes = [
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "member-profile", component: <MemberProfile /> },
    { url: "predictions", component: <PredictionsTaskSummary /> },
    { url: "goals", component: <GoalsTaskSummary /> },
    { url: "bucketlist", component: <BucketlistTaskSummary /> },
    { url: "announcements", component: <AnnouncementsTaskSummary /> },
    { url: "admin-access", component: <AdminAccess /> },
    { url: "admin-access-member", component: <AdminAccessMember /> },
    { url: "add-announcement/:id?", component: <AddAnnouncement /> },
    { url: "info", component: <Info /> },

];

const Markup = () => {   
  
  function NotFound(){    
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]    
      
    if(url.indexOf(path) <= 0){     
      return <Error404 />
    }
  }
   
    return (
      <>
          <Routes>              
              <Route path='/page-lock-screen' element= {<LockScreen />} />
              <Route path='/page-error-400' element={<Error400/>} />            
              <Route path='/page-error-403' element={<Error403/>} />
              <Route path='/page-error-404' element={<Error404/>} />
              <Route path='/page-error-500' element={<Error500/>} />
              <Route path='/page-error-503' element={<Error503/>} />     
              <Route  element={<MainLayout />} > 
                  {allroutes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`${data.url}`}
                      element={data.component}
                    />
                    ))}
              </Route>                
              <Route path='*' element={<NotFound/>} />     
          </Routes>           
      </>
    );       
};


  function MainLayout(){      
  
    const storedUserColor = localStorage.getItem("userDetails");
    const storedDetailsColor = JSON.parse(storedUserColor);
    const storedRoleColor = storedDetailsColor.data.role;
    const bgCustom_Blue = storedRoleColor.includes("Admin") ? "#01152F" : "#01152F";

    const sideMenu = useSelector(state => state.sideMenu);
    return (
      <div id="main-wrapper" className={`show ${ sideMenu ? "menu-toggle" : ""}`}  style={{ backgroundColor: bgCustom_Blue }}>  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 28, backgroundColor: bgCustom_Blue }}>          
            <Outlet />   
          </div>
      </div>
    )
  };

export default Markup;
 