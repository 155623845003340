// Countdown.js
import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../../../context/ThemeContext';
import moment from 'moment-timezone';

const CountdownItem = ({ label, value, backgroundColor, setCountdown, flip, setFlip }) => {
  const { changeBackground } = useContext(ThemeContext);

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });

    const calculateCountdown = () => {
      const newYearDate = moment().add(1, 'year').startOf('year');
      const userDateTime = moment.tz(moment(), moment.tz.guess());
      const countdownDuration = moment.duration(newYearDate.diff(userDateTime));

      setCountdown(countdownDuration);
    };

    calculateCountdown();

    const countdownInterval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(countdownInterval);
  }, [setCountdown, setFlip]);

  return (
    <div
      style={{
        backgroundColor,
        padding: '10px',
        margin: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        transition: 'transform 1.5s ease',
        transform: 'rotateY(0deg)',
      }}
    >
      <div style={{ color: '#FFFFFF', fontSize: '34px', fontWeight: 'bold' }}>{value}</div>
      <div style={{ color: '#DDDDDD', width: '56px' }}>{label}</div>
    </div>
  );
};

export default CountdownItem;
