import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    updateUserProfile,
  } from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export const USER_UPDATE_REQUEST = '[user update action] user update request';
export const USER_UPDATE_SUCCESS = '[user update action] user update success';
export const USER_UPDATE_FAILURE = '[user update action] user update failure';

export const ADD_NEW_GROUP_REQUEST = '[add new group action] add new group request';
export const ADD_NEW_GROUP_SUCCESS = '[add new group action] add new group success';
export const ADD_NEW_GROUP_FAILURE  = '[add new group action] add new group failure';

  export function addNewGroupRequest() {
    return {
    type: ADD_NEW_GROUP_REQUEST,
    };
  }
  
  export function addNewGroupSuccess(group) {
    return {
    type: ADD_NEW_GROUP_SUCCESS,
    payload: group,
    };
  }
  
  export function addNewGroupFailure(error) {
    return {
    type: ADD_NEW_GROUP_FAILURE,
    payload: error,
    };
  }

  export function userUpdateRequest() {
    return {
      type: USER_UPDATE_REQUEST,
    };
  }
  
  export function userUpdateSuccess(updatedUser) {
    return {
      type: USER_UPDATE_SUCCESS,
      payload: updatedUser,
    };
  }
  
  export function userUpdateFailure(error) {
    return {
      type: USER_UPDATE_FAILURE,
      payload: error,
    };
  }
  
  export function addNewGroupAction(payload, navigate) {
    return (dispatch) => {
        dispatch(addNewGroupRequest());
    
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
        if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          const token = tokenDetails.token;
    
          // Make API call to add a new group
          axios
            .post(`${process.env.REACT_APP_PREDICTIONS_API}/api/add-new-group`, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              // Assuming your API response contains the newly added group details
              const newGroup = response.data;
    
              // Dispatch success action
              dispatch(addNewGroupSuccess(newGroup));
              // Show success message using swal
              Swal.fire('Yay!', 'New group added.', 'success').then((value) => {
                window.location.reload();
              });
    
              // Optional: You can perform any additional actions or navigation here
              if (navigate) {
                //navigate('/dashboard'); // Example navigation to the dashboard
              }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    // Handle 404 error (Not Found)
                    Swal.fire('Oops!', 'Group already exists.', 'error');
                  } else {
                    // Handle other errors
                    const errorMessage = formatError(error.response.data);
                    Swal.fire('Oops!', errorMessage, 'error');
                    dispatch(addNewGroupFailure(errorMessage));
                }
            });
        }
      };
  }

  export function userUpdateAction(payload, navigate) {
    return (dispatch) => {
      dispatch(userUpdateRequest());

      const tokenDetailsString = localStorage.getItem('userDetails');
      if (tokenDetailsString) {
          const tokenDetails = JSON.parse(tokenDetailsString);
          const token = tokenDetails.token;
          
          // Call updateUserProfile with the token and payload
          updateUserProfile(token, payload)
            .then((response) => {
              // Assuming your API response contains updated user details
              const updatedUser = response.data;
      
              // Update localStorage
              const storedUser = JSON.parse(localStorage.getItem('userDetails')) || {};
              localStorage.setItem(
                'userDetails',
                JSON.stringify({ ...storedUser, ...updatedUser })
              );
      
              // Dispatch success action
              dispatch(userUpdateSuccess(updatedUser));
      
              // Optional: You can perform any additional actions or navigation here
              if (navigate) {
                navigate('/dashboard'); // Example navigation to the dashboard
              }
            })
            .catch((error) => {
              console.log(error,"My Error")
              const errorMessage = formatError(error.response.data);
              dispatch(userUpdateFailure(errorMessage));
            });
      }
    };
  }

export function signupAction(email, password, navigate) {
	
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
            navigate('/dashboard');
			//history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function Logout(navigate) {
    localStorage.removeItem('userDetails')

    localStorage.clear()
    sessionStorage.clear()

    navigate('/login')

    return {
        type: LOGOUT_ACTION,
    }
}

export function loginAction(email, password, navigate) {
    return  (dispatch) => {
         login(email, password)
            .then((response) => { 
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    // response.data.expiresIn * 1000,
                    process.env.REACT_APP_LOGIN_EXPIRES,
                    navigate,
                );
               dispatch(loginConfirmedAction(response.data));		              
				navigate('/dashboard');              
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {        
      type: 'NAVTOGGLE',
    };
};