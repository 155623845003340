import React, { useContext, useEffect, useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import { Tooltip } from 'react-tooltip'


import FooterBar from "../../Dashboard/FooterBar";
import MainPagetitle from "../../../layouts/PredictionsPagetitle";
import { IMAGES, SVGICON } from "../../../constant/theme";
import { FaCube, FaRegGem, FaClipboardList } from "react-icons/fa";
import EditProfile from "./EditProfile";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";
import { userUpdateAction } from "../../../../store/actions/AuthActions";
import { uploadFileToS3 } from "../../../../config/config";
import { setCurrentUser } from "../../../../store/actions/UserActions";

const PredictionsMemberProfile = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [activeTab, setActiveTab] = useState("Profile"); // Initialize with the default tab
  const [score, setScore] = useState({
    bucketScore: 0,
    templateScore: 0,
    goalScore: 0,
  });
  const onInit = () => {};
  const iconStyle = {
    fontSize: "1.4em", // Adjust the font size as needed
  };
  const iconSize = 30;

  const dispatch = useDispatch();

  const updateProfileImage = async (imageUrl) => {
    dispatch(userUpdateAction({ pic: imageUrl }));
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const { data, token, expireDate } = JSON.parse(storedUserDetails);
      const updatedUser = {
        ...data,
        pic: imageUrl,
      };
      localStorage.setItem(
        "userDetails",
        JSON.stringify({ data: updatedUser, token, expireDate })
      );
      currentUser.pic = imageUrl;
      setTimeout(() => {
        dispatch(setCurrentUser(updatedUser));
      }, 2000);
    }
  };

  const getScore = async () => {
    try {
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      const currentGroup = localStorage.getItem("user_initial_Group");

      const scoreData = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/get-score/${currentGroup}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const scoreResult = await scoreData.json();
      setScore({ ...scoreResult });
    } catch (error) {}
  };
  useEffect(() => {
    getScore();
  }, []);

  const storedUserColor = localStorage.getItem("userDetails");
  const storedDetailsColor = JSON.parse(storedUserColor);
  const storedRoleColor = storedDetailsColor.data.role;
  const bgCustom_Blue = storedRoleColor.includes("Admin") ? "#01152F" : "";

  return (
    <div style={{ backgroundColor: bgCustom_Blue }}>
    <div className="container-fluid" style={{ height: '440px', backgroundColor: bgCustom_Blue }}>
      <MainPagetitle
        mainTitle="Profile"
        pageTitle="Profile"
        parentTitle="Dashboard"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4 col-xxl-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card card-profile">
                  <div className="admin-user">
                    <div className="user-details">
                      <div className="card-body">
                        <div className="author-profile">
                          <div className="author-media"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content=""
                            data-tooltip-place="top"
                            title=""
                            Tooltip=""
                          >
                            <img
                              src={
                                currentUser.pic && currentUser.pic !== ""
                                  ? currentUser.pic
                                  : IMAGES.Tab1
                              }
                              alt=""
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content=""
                              data-tooltip-place="top"
                              title=""
                              Tooltip=""
                            />
                            <div
                              className="upload-link"
                              title=""
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content=""
                              data-tooltip-place="top"
                              Tooltip=""
                            >
                              <input
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content=""
                                data-tooltip-place="top"
                                title="Add profile photo"
                                Tooltip=""
                                type="file"
                                className="update-flie"
                                onChange={(e) => {
                                  const fileName = `Profile/${Date.now()}`;
                                  uploadFileToS3(
                                    e.target.files[0],
                                    (data) => updateProfileImage(data),
                                    fileName
                                  );
                                }}
                              />
                              <i className="fa fa-camera" alt="add profile photo" title=" add profile photo"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content=""
                                data-tooltip-place="top"
                                Tooltip=""
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      {1===0 && currentUser && currentUser.role !== "globalAdmin" && (
                        <ul className="follow-list">
                          <li>
                            <div className="follow-num " style={iconStyle}>
                              {score.templateScore}
                            </div>
                            <span>
                              <FaCube size={iconSize} />
                            </span>
                          </li>
                          <li>
                            <div className="follow-num " style={iconStyle}>
                              {score.goalScore}
                            </div>
                            <span>
                              <FaRegGem size={iconSize} />
                            </span>
                          </li>
                          <li>
                            <div className="follow-num " style={iconStyle}>
                            {score.bucketScore}
                            </div>
                            <span>
                              <FaClipboardList size={iconSize} />
                            </span>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-xxl-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body profile-accordion pb-0">
                    <Accordion
                      className="accordion"
                      id="accordionExample2"
                      defaultActiveKey={[]}
                    >
                      <Accordion.Item className="accordion-item">
                        <Accordion.Header
                          as="h2"
                          className="accordion-header"
                          id="headingOne2"
                        >
                          Profile
                        </Accordion.Header>
                        <Accordion.Body
                          id="collapseOneM"
                          className="accordion-collapse collapse show"
                        >
                          <EditProfile currentUser={currentUser} />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-xxl-4">
          <div className="row">&nbsp;</div>
        </div>
      </div>
      <div>&nbsp;</div>
      <FooterBar activeTab={activeTab} currentUser={currentUser} />{" "}
      {/* Pass activeTab as a prop */}
      </div>
    </div>
  );
};

export default PredictionsMemberProfile;
