import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from '../../../context/ThemeContext';
import CountdownDisplay from './elements/CountdownDisplay';
import MainPagetitle from '../../layouts/PredictionsPagetitle';
import UiUploadPics from '../bootstrap/PredictionsPics';
import StatsTab from '../bootstrap/Stats';
import Announcements from '../bootstrap/Announcements';
import FooterBar from '../../components/Dashboard/FooterBar';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const Home = () => {
  const moment = require('moment-timezone');
  const currentUser = useSelector((state) => state.user.currentUser);
  const { changeBackground } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('Home'); // Initialize with the default tab
  const [countdown, setCountdown] = useState(null);
  const [flip, setFlip] = useState(false);

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });

    // Calculate the countdown
    const calculateCountdown = () => {
      const newYearDate = moment.tz('2024-01-01T00:00:00', 'UTC'); // Adjust the year as needed
      const userDateTime = moment.tz(moment(), moment.tz.guess());
      const countdownDuration = moment.duration(newYearDate.diff(userDateTime));

      setCountdown(countdownDuration);
    };

    // Run the countdown calculation initially
    calculateCountdown();

    // Set up a timer to update the countdown every second
    const countdownInterval = setInterval(calculateCountdown, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(countdownInterval);

  }, []);
  
  // Set Greeting 
  
  const storedUserColor = localStorage.getItem("userDetails");
  const storedDetailsColor = JSON.parse(storedUserColor);
  const storedRoleColor = storedDetailsColor.data.role;
  const bgCustom_Blue = storedRoleColor.includes("Admin") ? "#01152F" : "#01152F";

  return (
    <div style={{ backgroundColor: bgCustom_Blue, height: '100%', 
    display: "flex", // Center the form horizontally
    justifyContent: "center", }}>
    <div className="container-fluid" 
      style={{ 
        backgroundColor: bgCustom_Blue, 
        height: '100%' }}
    >
      <MainPagetitle mainTitle="Home" pageTitle="Dashboard" parentTitle="Home" /> 
      {currentUser && (currentUser.role === 'globalAdmin' || currentUser.email === 'groupadmin@nyepredictions.com') && (
        <div className="container-fluid" style={{ height: '350px', backgroundColor: bgCustom_Blue }}>
          &nbsp;
          <div className="row" style={{ backgroundColor: bgCustom_Blue }}>
            &nbsp;
            <div className="col-xl-12 wid-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: bgCustom_Blue }}>
              <div style={{ backgroundColor: bgCustom_Blue }}>
              <h1 style={{ fontFamily: 'Verdana', color: '#F1D34F' }}>New Year's Eve Countdown</h1>
              </div>
            </div>
              <CountdownDisplay
              countdown={countdown}
              setCountdown={setCountdown}
              flip={flip}
              setFlip={setFlip}
            />
          </div>
        </div>
      )}
      {currentUser && (currentUser.role === 'globalAdmin' || currentUser.email === 'groupadmin@nyepredictions.com') && (
        <div className="container-fluid" style={{ height: '350px', backgroundColor: bgCustom_Blue }}>
          &nbsp;
          <div className="row" style={{ backgroundColor: bgCustom_Blue }}>
            &nbsp;
            <div className="col-xl-12 wid-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: bgCustom_Blue }}>
            {currentUser.role === 'globalAdmin' && (
              <span style={{ height: '350px' }}>Global Admin</span>
            )}
            {currentUser.email === 'groupadmin@nyepredictions.com' && (
              <span style={{ height: '350px' }}>Group Admin</span>
            )}
          </div>
          </div>
        </div>
      )}
      {currentUser && (currentUser.role !== 'globalAdmin' && currentUser.email !== 'groupadmin@nyepredictions.com') && (
        <div  style={{ backgroundColor: bgCustom_Blue }}>
        <div className="container-fluid" style={{ backgroundColor: bgCustom_Blue }}>
          <div className="row" style={{ backgroundColor: bgCustom_Blue }}>
            <div className="col-xl-12 wid-100" style={{ backgroundColor: bgCustom_Blue }}>
              <UiUploadPics />
              <StatsTab
                onTabChange={(newTab) => setActiveTab(newTab)} // Pass a callback to update activeTab
              />
              <Announcements />
            </div>
            {" "}
          </div>		
          <div className="row" style={{ backgroundColor: bgCustom_Blue }}>
            <div className="col-xl-12 mt-3" style={{ backgroundColor: bgCustom_Blue }}>
              {" "}
            </div>
          </div>	
          <div className="row" style={{ backgroundColor: bgCustom_Blue }}>
            <div className="col-xl-12 mt-3" style={{ backgroundColor: bgCustom_Blue }}>
              {" "}
            </div>
          </div>
        </div>
        </div>
      )}
      <FooterBar activeTab={activeTab} currentUser={currentUser} /> {/* Pass activeTab as a prop */}
    </div>
    </div>
  );
};

export default Home;
