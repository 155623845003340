import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from "../../layouts/PredictionsPagetitle";
import Timeline from "../bootstrap/AnnouncementsView";
import FooterBar from "./FooterBar";
import { useSelector } from "react-redux";
import bg6 from "../../../images/background/nye-slim-2600.png";

const Home = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const task = useRef();
  const { changeBackground } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState("Milestones"); // Initialize with the default tab

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  return (
    /* <div style={{ background: `#fff url(${bg6}) center center / cover no-repeat`, height: "100vh" }}> */
    <div>
      <div
        className="container-fluid">
        <MainPagetitle
          mainTitle="Announcements"
          pageTitle="Announcements"
          parentTitle="Home"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 wid-100">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-12 wid-100">
                    <div className="">
                      <div className="">
                        <Link to="/add-announcement">
                          <button
                            variant="primary"
                            className="btn btn-primary dz-xs-flex"
                            style={{
                              backgroundColor: "#FFF8AF",
                              width: "120px",
                              height: "28px",
                              margin: "0px",
                              padding: "0px",
                              color: "black",
                            }}
                          >
                            Add Yours
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Timeline />
              &nbsp;
            </div>
            &nbsp;
          </div>
          <div className="row">
            <div className="col-xl-12 mt-3"> </div>
          </div>
          <div className="row">
            <div className="col-xl-12 mt-3"> </div>
          </div>
        </div>
        <FooterBar activeTab={activeTab} currentUser={currentUser} />{" "}
        {/* Pass activeTab as a prop */}
      </div>
    </div>
  );
};

export default Home;
