import React,{useState} from 'react';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import Swal from 'sweetalert2';

const ChangeUserPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleChangeEmail = (e) => {
        // Update the email state on input change
        setEmail(e.target.value);
    };

    const handleChangePwd = (e) => {
        // Update the password state on input change
        setPassword(e.target.value);
    };

    const handleSendInvitation = async () => {        
        // Check if the password input is not empty
        if (password.trim() === "") {
            // Show a Swal indicating that the password input is required
            Swal.fire('Oops!', 'Please enter a password.', 'error');
            return;
        }    
        // Check if the email input is not empty
        if (email.trim() === "") {
            // Show a Swal indicating that the email input is required
            Swal.fire('Oops!', 'Please enter a valid email address.', 'error');
            return;
        } 
        if (password && email.trim() !== "") {
            try {
                const tokenDetailsString = localStorage.getItem('userDetails');
                const tokenDetails = JSON.parse(tokenDetailsString);
                const token = tokenDetails.token;
                // Call your API here using fetch or axios
                const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/change-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                    password: password,
                    email: email,
                    oldPassword: null,
                    newPassword: null,
                    userId: null,
                    }),
                });
            
                if (response.ok) {
                    await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/member-connect`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        userId:email,
                        groupId: 'abcdef',
                        mode: '004'
                      }),
                    });
                    // Show a success Swal or perform the desired action
                    Swal.fire('Done!', 'Password changed successfully.', 'success').then((result) => {
                      // If the user clicks "OK", result.value will be true
                      if (result.isConfirmed) {
                        // Reload the page after the user clicks "OK"
                        window.location.reload();
                      }
                    });
                } else {
                    // Handle API error and show an error Swal
                    Swal.fire('Oops!', 'User not found.', 'error');
                }
            } catch (error) {
                console.error('Error sending invitation:', error);
                // Show an error Swal for unexpected errors
                Swal.fire('Oops!', 'Something went wrong.', 'error');
            }

        }
    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    
                        <div className="card profile-card card-bx">
                            <form className="profile-form">
                                <div className="card-body">
                                    <div>
                                        <div className="" key="1">
                                        <label className="form-label">User Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            onChange={handleChangeEmail} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="" key="2">
                                        <label className="form-label">New Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control"  
                                            onChange={handleChangePwd} />
                                        </div>
                                    </div>
                                    &nbsp;
                                </div>
                                &nbsp;
                                <div className="row">
                                    <button 
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSendInvitation}>Update</button>
                                </div>
                            </form>
                        </div>
                        
                </div>
            </div>
        </>
    )
}
export default ChangeUserPassword;