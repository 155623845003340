import React, { useContext, useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // Import the styles for the toggle
import "react-toggle/style.css"; // Import the styles for the toggle
import { Row, Col, Card, Form, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios"; // Import Axios
import moment from "moment-timezone"; // Add this line for moment
import CountdownDisplay from "./../Dashboard/elements/CountdownDisplay";

const PredictionsViewData = ({ predictionsData, presentYear, storedUserId,  updateTrigger, setUpdateTrigger }) => {
  const [storedRoleEnhanced, setStoredRoleEnhanced] = useState('?');
  const [currentGroupRole, setCurrentGroupRole] = useState('?');
  const [isLocked, setIsLocked] = useState(false);
  const [isSealed, setIsSealed] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [answerability, setAnswerability] = useState(false);
  const [launchEvent, setLaunchEvent] = useState(true);
  const [startScore, setStartScore] = useState(false);
  const [sliderValue, setSliderValue] = useState({});
  const [countdown, setCountdown] = useState(null);
  const [flip, setFlip] = useState(false);

  const [inputValues, setInputValues] = useState({});
  
  const storedUser = localStorage.getItem("userDetails");
  const storedDetails = JSON.parse(storedUser);
  let storedRole = storedDetails.data.role;

  const handleInputChange = (taskid, value) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [taskid]: value
    }));
  };

  const handleLockChange = () => {
    // Display Swal confirmation dialog
    swal({
      text: isLocked ? `Lock the predictions?` : `Open the predictions now?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmed) => {
      // If user confirms, toggle the state and update visibility
      if (confirmed) {
        const groupName = localStorage.getItem("user_initial_Group");
        let newGameStatus = "?";
        let gamePlay = "-";
        if(!isLocked){
          setIsLocked(true);
          setIsSealed(false);
          setVisibility(true);
          setAnswerability(true);
          setLaunchEvent(true);
          setStartScore(false);
          newGameStatus = "?";
          gamePlay = "answerNow";
        } else {
          setIsLocked(false);
          setIsSealed(false);
          setVisibility(true);
          setAnswerability(false);
          setLaunchEvent(true);
          setStartScore(false);
          newGameStatus = "?";
          gamePlay = "answerLocked";
        }
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
          }
        );

        setUpdateTrigger();
      }
    });
  };

  const handleToggleChange = () => {
    // Display Swal confirmation dialog
    swal({
      text: isSealed ? `Seal the answers now?` : `Ready for the big reveal?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmed) => {
      // If user confirms, toggle the state and update visibility
      if (confirmed) {
        let newGameStatus = "?";
        let gamePlay = "-";
        
        if(isSealed){
          setIsLocked(false);
          setIsSealed(false);
          setVisibility(true);
          setAnswerability(false);
          setLaunchEvent(true);
          setStartScore(false);
          newGameStatus = "open";
          gamePlay = "sealed";
        } else {
          setIsLocked(false);
          setIsSealed(false);
          setVisibility(true);
          setAnswerability(false);
          setLaunchEvent(true);
          setStartScore(true);
          newGameStatus = "open";
          gamePlay = "beginScoring";
        }
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );  
        
        setUpdateTrigger();
      }
    });
  };

  const [predictionsGroupRole, setPredictionsGroupRole] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the backend API
        const userId = storedUserId;
        const groupName = localStorage.getItem("user_initial_Group");

        const responseRole = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/user-group-role`, {
          params: { userId, groupName },
        });
        const userGroupRole = responseRole.data.userGroupRole; // Assuming the response has a property named 'userGroupRole'
        
        // Store the result in the component's state
        setPredictionsGroupRole(userGroupRole);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        // Handle error as needed
      }
    };


    // Calculate the countdown
    const calculateCountdown = () => {
      const newYearDate = moment.tz("2024-01-01T00:00:00", "UTC"); // Adjust the year as needed
      const userDateTime = moment.tz(moment(), moment.tz.guess());
      const countdownDuration = moment.duration(newYearDate.diff(userDateTime));

      setCountdown(countdownDuration);
    };

    // Run the countdown calculation initially
    calculateCountdown();

    // Set up a timer to update the countdown every second
    const countdownInterval = setInterval(calculateCountdown, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(countdownInterval);

    fetchData(); // Fetch data when the component mounts
  }, []); // Empty dependency array to run the effect only once

  const handleLaunchEvent = async () => {
    swal({
      title: "Start your NYE Party?",
      icon: "warning",
      buttons: ["Cancel", "OK"],
    }).then(async (value) => {
      if (value) {
        // User clicked "OK," proceed with launching the event
        let newGameStatus = "?";
        let gamePlay = "-";
        
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(false);
        newGameStatus = "open";
        gamePlay = "sealed";
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: newGameStatus,
            currentPlay: gamePlay,
          }
        );
        
        setUpdateTrigger();
                
      } else {
        // User clicked "Cancel" or closed the dialog
        // You can handle this case or leave it empty if no action is needed
      }
    });
  }
  
  const handleSliderChange = (taskId, newScore) => {
    // Update the local state with the slider values
    setSliderValue((prevSliderValues) => ({
      ...prevSliderValues,
      [taskId]: newScore,
    }));
  };

  const handleSaveScores = async () => {
    const scoresToUpdate = Object.entries(sliderValue).map(([taskid, score]) => ({
      taskid,
      score,
      // Add other relevant data
    }));

    try {
      await axios.post(`${process.env.REACT_APP_PREDICTIONS_API}/api/save-scores/${localStorage.getItem("user_initial_Group")}/${presentYear}`, {
        userId: storedUserId,
        scores: scoresToUpdate,
      });
      
      await new Promise(resolve => setTimeout(resolve, 500));

      await axios.post(`${process.env.REACT_APP_PREDICTIONS_API}/api/save-scores/${localStorage.getItem("user_initial_Group")}/${presentYear}`, {
        userId: storedUserId,
        scores: scoresToUpdate,
      });
     
      // Optionally, show a success message to the user
      Swal.fire("Thank you!", "Scores saved.", "success").then((result) => {
        if (result.isConfirmed) { 
          //
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleSaveAnswers = async () => {
    try {
      const userId = storedUserId;
      const groupName = localStorage.getItem("user_initial_Group");
      const year = presentYear;
  
      // Check if year is null or undefined
      if (!year) {
        console.error("Year is null or undefined.");
        return;
      }
  
      // Prepare data for the backend
      const answersData = Object.entries(inputValues).map(([taskid, value]) => ({
        taskid,
        answer: value,
      }));
  
      // Add the year, userId, and groupName to each answer object
      const answersWithMetadata = answersData.map(answer => ({
        ...answer,
        year,
        userId,
        groupName,
      }));
  
      // Send data to MongoDB backend using Axios or your preferred HTTP library
      await axios.post(`${process.env.REACT_APP_PREDICTIONS_API}/api/save-answers`, {
        answersWithMetadata: answersWithMetadata
      });

      // Send data to MongoDB backend using Axios or your preferred HTTP library
      await axios.post(`${process.env.REACT_APP_PREDICTIONS_API}/api/save-answers`, {
        answersWithMetadata: answersWithMetadata
      });
      
      await new Promise(resolve => setTimeout(resolve, 500));

      // Send data to MongoDB backend using Axios or your preferred HTTP library
      await axios.post(`${process.env.REACT_APP_PREDICTIONS_API}/api/save-answers`, {
        answersWithMetadata: answersWithMetadata
      }); 
     
      // Optionally, show a success message to the user
      swal("Answers saved successfully!", {
        icon: "success",
      });

    } catch (error) {
      console.error("Error saving answers:", error);
  
      // Optionally, show an error message to the user
      swal("Error saving answers", {
        icon: "error",
      });
    }
  };

  const resetParty = async () => {
    Swal.fire({
      title: `Start over ${presentYear}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: "Yes, let's play again!",
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(false);
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: "?",
            currentPlay: "answerNow",
          }
        );

        
        setUpdateTrigger();
        
      }
    });
  };

  const closeParty = async () => {
    Swal.fire({
      title: `Wrap-up ${presentYear}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, we had fun!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(false);
        
        await axios.put(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/update-game/${localStorage.getItem("user_initial_Group")}/${presentYear}`,
          {
            isLocked: isLocked,
            isSealed: isSealed,
            visibility: visibility,
            answerability: answerability,
            launchEvent: launchEvent,
            startScore: startScore,
            gameStatus: "closed",
            currentPlay: "-",
          }
        );
        Swal.fire({
          title: `Closed ${presentYear}!`,
          icon: 'success',
          confirmButtonText: 'See you next year!',
        });

        
        setUpdateTrigger();

      }
    });
  };

  useEffect(() => {
    // Update state based on prediction.mode
    predictionsData.forEach((prediction) => {
      if (prediction.currentPlay === "answerLocked") {
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(false);
      } else if (prediction.currentPlay === "answerNow") {
        setIsLocked(true);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(true);
        setLaunchEvent(true);
        setStartScore(false);
      } else if (prediction.currentPlay === "sealed") {
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(false);
      } else if (prediction.currentPlay === "beginScoring") {
        setIsLocked(false);
        setIsSealed(false);
        setVisibility(true);
        setAnswerability(false);
        setLaunchEvent(true);
        setStartScore(true);
      }
    });
  
  }, [predictionsData]);

  const fetchRoleData = async () => {
    try {
      const chkrUserRole = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUserGroupsAdmin/${storedUserId}`);
      const userGroupsInfo = chkrUserRole.data.data.userGroups;
      const hasGroupAdminRole = userGroupsInfo.some(group => group.role === 'groupAdmin');
      
      setCurrentGroupRole(userGroupsInfo.find(group => group.groupName === localStorage.getItem("user_initial_Group"))?.role || "not-admin");

      if (hasGroupAdminRole) {
        setStoredRoleEnhanced('groupAdmin');
        storedRole = storedRoleEnhanced;
      } else{
        setStoredRoleEnhanced(storedRole);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []); // Run this effect only once when the component mounts

  let answerDisplayed = 0;
  return (
    <div className="col" style={{ }}>
      {/*
      [currentGroupRole: {currentGroupRole}]
      [storedRole: {storedRole}]
      [storedRoleEnhanced: {storedRoleEnhanced}]
      [isLocked: {isLocked.toString()}]
      [isSealed: {isSealed.toString()}]
      [visibility: {visibility.toString()}]
      [answerability: {answerability.toString()}]
      [launchEvent: {launchEvent.toString()}]
      [startScore: {startScore.toString()}]
      [[{predictionsData.isLocked}]]
      */}
      <div className="card kanbanPreview-bx" style={{ backgroundColor: '#F3F3F4' }}>
        <div>
          {predictionsData && predictionsData.length > 0 && predictionsData.map((prediction) => (
            <div key={prediction._id}>
              {/* [STATUS: {prediction.gameStatus}][PLAY: {prediction.currentPlay}] */}
              <div>
              {(storedRoleEnhanced === "groupAdmin" && currentGroupRole==="groupAdmin") && (
                <div style={{ marginLeft: '10px', marginTop: '2px', display: "flex", alignItems: "center", height: '65px' }}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "50%",
                        marginLeft: "-10px",
                        marginTop: "12px",
                        backgroundColor: '#01152F',
                        borderColor: '#01152F'
                      }}
                      onClick={resetParty}
                    >
                      Reset {presentYear} Party?
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "50%",
                        marginLeft: "-10px",
                        marginTop: "12px",
                        backgroundColor: '#FF0000',
                        borderColor: '#FF0000'
                      }}
                      onClick={closeParty}
                    >
                      Close {presentYear} Party?
                    </button>
                </div>
              )}
              </div>              
              {prediction.mode === "missing" ? (
                <div style={{ backgroundColor: '#01152F' }}>
                  <div style={{ backgroundColor: '#01152F', textAlign: 'center' }}>
                    <h1>&nbsp;</h1>
                    <CountdownDisplay
                      countdown={countdown}
                      setCountdown={setCountdown}
                      flip={flip}
                      setFlip={setFlip}
                    />
                    <h1>&nbsp;</h1>
                    <h5 style={{color: '#FF8888'}}>&nbsp;&nbsp;Please contact your group admin</h5>
                    <h5 style={{color: '#FFAAAA'}}>&nbsp;&nbsp;(no Predictions data found)</h5>
                    <h1>&nbsp;</h1>
                  </div>
                </div>
              ) : (
                <div>
                {prediction.gameStatus === "?" && (
                  <div>
                    <div style={{ display: "flex", alignItems: "center", height: '65px'  }}>
                      {currentGroupRole==="groupAdmin" && (storedRoleEnhanced === "groupAdmin" || predictionsGroupRole === "groupAdmin") && !isSealed && !startScore ? (
                      <div style={{ display: "flex", alignItems: "center", height: '65px'  }}>
                        <span
                          style={{
                            padding: "1px",
                            fontSize: "14px",
                            verticalAlign: "top",
                            marginTop: "2px",
                            color: '#01152F',
                            fontWeight: 'bold'
                          }}
                        >
                          Locked&nbsp;
                        </span>
                        <Toggle checked={isLocked} onChange={handleLockChange} />
                        <span
                          style={{
                            padding: "1px",
                            fontSize: "14px",
                            verticalAlign: "top",
                            marginTop: "2px",
                            color: '#01152F',
                            fontWeight: 'bold'
                          }}
                        >
                          &nbsp;Open
                        </span>
                      </div>
                      ) : (
                        <div>
                          <h3 style={{ color: '#01152F',marginTop: '4px' }}>Let's celebrate!</h3>
                        </div>
                      )}
                      {answerability && (
                        <div style={{ marginLeft: '20px', marginTop: '-10px', display: "flex", alignItems: "center", height: '65px' }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                height: "40px",
                                width: "155px",
                                marginLeft: "-10px",
                                marginTop: "12px",
                              }}
                              onClick={handleSaveAnswers}
                            >
                              Save Answers
                            </button>
                        </div>
                      )}
                      {launchEvent && (storedRoleEnhanced === "groupAdmin" && currentGroupRole==="groupAdmin") && (
                        <div style={{ marginLeft: '60px', marginTop: '-10px', display: "flex", alignItems: "center", height: '65px' }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                height: "40px",
                                width: "120px",
                                marginLeft: "-10px",
                                marginTop: "12px",
                                backgroundColor: "red",
                                borderColor: "red",
                              }}
                              onClick={handleLaunchEvent}
                            >
                              Reveal
                            </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {prediction.gameStatus === "open" && (
                  <Row className="justify-content-between align-items-center">
                    <div>
                      <div style={{ display: "flex", alignItems: "center", height: '65px'  }}>
                        {currentGroupRole==="groupAdmin" && (storedRoleEnhanced === "groupAdmin" || predictionsGroupRole === "groupAdmin") ? (
                        <div style={{ display: "flex", alignItems: "center", height: '65px'  }}>
                          <span
                            style={{
                              padding: "1px",
                              fontSize: "14px",
                              verticalAlign: "top",
                              marginTop: "2px",
                              color: '#01152F',
                              fontWeight: 'bold'
                            }}
                          >
                            Sealed&nbsp;
                          </span>
                          <Toggle checked={startScore} disabled={startScore} onChange={handleToggleChange} />
                          <span
                            style={{
                              padding: "1px",
                              fontSize: "14px",
                              verticalAlign: "top",
                              marginTop: "2px",
                              color: '#01152F',
                              fontWeight: 'bold'
                            }}
                          >
                            &nbsp;Reveal
                          </span>
                        </div>
                        ) : (
                          <div>
                            <h3 style={{ color: '#01152F',marginTop: '4px' }}>Let's celebrate!</h3>
                          </div>
                        )}
                        {visibility && prediction.currentPlay === "beginScoring" && (
                          <div style={{ marginLeft: '20px', marginTop: '-10px', display: "flex", alignItems: "center", height: '65px' }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                height: "40px",
                                width: "155px",
                                marginLeft: "-10px",
                                marginTop: "12px",
                              }}
                              disabled={!startScore}
                              onClick={handleSaveScores}
                            >
                              Save Scores
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                )}
                
                {prediction.gameStatus === "closed" && (
                  <Row className="justify-content-between align-items-center">
                    <div>

                      <div style={{ display: "flex", alignItems: "center", height: '65px' }}>
                        <span
                          style={{
                            padding: "1px",
                            fontSize: "14px",
                            verticalAlign: "top",
                            marginTop: "2px",
                            color: '#01152F',
                            fontWeight: 'bold'
                          }}
                        >
                          Sealed&nbsp;
                        </span>
                        <Toggle checked disabled />
                        <span
                          style={{
                            padding: "1px",
                            fontSize: "14px",
                            verticalAlign: "top",
                            marginTop: "2px",
                            color: '#01152F',
                            fontWeight: 'bold'
                          }}
                        >
                          &nbsp;Reveal
                        </span>
                      </div>
                    </div>
                  </Row>
                )}
                {prediction.questions.map((question) => (
                  question.text !== "(new prediction question)" && (
                    <div className="sub-card p-0" key={question.taskid} style={{ width:"96%", marginLeft: "6px", marginBottom: "20px" }}>
                      <div className="task-card-data" style={{ backgroundColor: "#F3F3F4" }}>
                        <div className="row">
                          <div className="" style={{ backgroundColor: '#FFF8AF', border: '1px solid #F1D34F' }}>
                            <h3 style={{ color: "#000000" }}>{question.text}</h3>
                          </div>
                          {prediction.gameStatus === "?" && answerability && (
                            <>
                            {answerDisplayed = null}
                            <div className="">
                              {prediction.memberAnswers.length > 0 ? (
                                prediction.memberAnswers.map((memberAnswer) => {
                                  const matchingAnswer = memberAnswer.answers.find(answer => answer.taskid === question.taskid);
                                  const answerOwnerId =  memberAnswer.userId;
                                  return (
                                    <React.Fragment key={storedUserId}>
                                    {answerOwnerId === storedUserId && (
                                      <>
                                        {(() => {
                                          answerDisplayed = 3;
                                          return (
                                            <div style={{ padding: '10px', marginLeft: '-11px' }}>
                                              <input
                                                className="form-control"
                                                type="text"
                                                value={inputValues[question.taskid] || (matchingAnswer ? matchingAnswer.answer : '')}
                                                style={{
                                                  height: "40px",
                                                }}
                                                onChange={(e) => handleInputChange(question.taskid, e.target.value)}
                                              />
                                            </div>
                                          );
                                        })()}
                                      </>
                                    )}
                                  </React.Fragment>
                                  );
                                })
                              ) : (
                                <React.Fragment key={storedUserId}>
                                  {(answerDisplayed = 4) && (
                                  <div style={{ padding: '10px', marginLeft: '-11px' }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={inputValues[question.taskid] || ''}
                                      style={{
                                        height: "40px",
                                      }}
                                      onChange={(e) => handleInputChange(question.taskid, e.target.value)}
                                    />
                                  </div>
                                  )}
                                </React.Fragment>
                              )}
                              {(answerDisplayed === null || answerDisplayed === 0) && (
                                <React.Fragment key={storedUserId}>
                                  {(answerDisplayed = 5) && (
                                    <div style={{ padding: '10px', marginLeft: '-11px' }}>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={inputValues[question.taskid] || ''}
                                        style={{
                                          height: "40px",
                                        }}
                                        onChange={(e) => handleInputChange(question.taskid, e.target.value)}
                                      />
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                              Please save your answers when done.
                              Once locked, your answers will be revealed at the New Year's party!
                            </div>
                            </>
                          )}
                          {prediction.gameStatus === "closed" ? (
                            <div className="col-md-12" style={{ minHeight: '60px', paddingTop: '3px', backgroundColor: '#F3F3F4' }}>
                              <span>
                                <h6 style={{ backgroundColor: "#F3F3F4" }}>
                                  {prediction.memberAnswers.map((memberAnswer) => {
                                    const matchingAnswer = memberAnswer.answers.find(answer => answer.taskid === question.taskid);
                                    if (memberAnswer.userId === storedUserId && matchingAnswer) {
                                      return (
                                        <React.Fragment key={memberAnswer.userId}>
                                          {matchingAnswer.answer && (
                                            <div className="footer-data-container">
                                              <div className="footer-data d-flex flex-row">
                                                <h4>{matchingAnswer.answer}</h4>
                                              </div>
                                              <div>
                                                <h6>&nbsp;</h6>
                                              </div>
                                              <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ height: '30px', textAlign: 'center'}}>
                                                <div>
                                                <RangeSlider
                                                  value={matchingAnswer.score}
                                                  tooltip="off"
                                                  step={25}
                                                  min={0}
                                                  max={100}
                                                  disabled
                                                />
                                                </div>
                                                <div>
                                                  <h4>&nbsp;Scored: {matchingAnswer.score}</h4>
                                                </div>
                                              </div>
                                              <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ height: '30px', textAlign: 'center'}}>
                                                <span><font color="gray">{new Date(matchingAnswer.answerTime).toLocaleString('en-US')}</font></span>
                                              </div>
                                            </div>
                                          )}
                                        </React.Fragment>
                                      );                                  
                                    }
                                    return null;
                                  })}
                                </h6>
                              </span>
                            </div>
                          ) : (
                            <div className="col-md-12" style={{ minHeight: '60px', paddingTop: '3px', backgroundColor: '#F3F3F4' }}>
                              {visibility ? (
                                <div>
                                  <span>
                                    <h6 style={{ backgroundColor: "#F3F3F4" }}>
                                      {prediction.memberAnswers.map((memberAnswer) => {
                                        const matchingAnswer = memberAnswer.answers.find(answer => answer.taskid === question.taskid);
                                        if (memberAnswer.userId === storedUserId && matchingAnswer) {
                                          return (
                                            <React.Fragment key={memberAnswer.userId}>
                                              {matchingAnswer.answer && startScore && (
                                                <div className="footer-data-container">
                                                  <div className="footer-data d-flex flex-row" style={{ height: '20px'}}>
                                                    <span><font color="gray">{new Date(matchingAnswer.answerTime).toLocaleString('en-US')}</font></span>
                                                  </div>
                                                  <div className="footer-data d-flex flex-row">
                                                    <h4>{matchingAnswer.answer}</h4>
                                                  </div>
                                                  <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                                                    <h6>&nbsp;</h6>
                                                  </div>
                                                  <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                                                    <h5 style={{ color: '#AA0000'}}>Please score your answer</h5>
                                                  </div>
                                                  <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ height: '30px', textAlign: 'center'}}>
                                                    <div>   
                                                      <div className="footer-data d-flex flex-row">
                                                      <RangeSlider
                                                        tooltip="off"
                                                        step={25}
                                                        min={0}
                                                        max={100}
                                                        onChange={(e) => {
                                                          handleSliderChange(question.taskid, Number(e.target.value));
                                                        }}
                                                        variant="danger"
                                                        value={sliderValue[question.taskid] || matchingAnswer.score}
                                                      />
                                                      </div>
                                                    </div>
                                                    <span><h1> </h1></span>
                                                    <div>
                                                      <div className="footer-data d-flex flex-row">
                                                        <h4>&nbsp;Score: <font color='#AA0000'>{sliderValue[question.taskid] || matchingAnswer.score}</font></h4>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );                                  
                                        }
                                        return null;
                                      })}
                                    </h6>
                                  </span>
                                </div>
                              ) : (
                                <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ height: '35px', textAlign: 'center'}}>
                                  <h6 style={{ color: '#01152F'}}>please wait for the grand reveal</h6>
                                </div>
                              )}
                              {prediction.gameStatus === "?" && !answerability && (
                                <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                                  {launchEvent && (
                                  <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                                      <h6 style={{ color: '#01152F'}}>Stay Tuned! Group Admin will open the answer fields and you can enter your predictions!</h6>
                                    </div>
                                  )}
                                </div>
                              )}
                              {prediction.currentPlay === "sealed" && visibility && !answerability && (
                                <div className="footer-data d-flex flex-row align-items-center justify-content-center" style={{ textAlign: 'center'}}>
                                  <h6 style={{ color: '#01152F'}}>please wait for the grand reveal</h6>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PredictionsViewData;
