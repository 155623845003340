import React,{useState} from 'react';
import GroupList from '../../../components/bootstrap/PredictionsListGroup';
import GroupMemberList from '../../../components/bootstrap/PredictionsListGroupMembers';
import Swal from 'sweetalert2';

const inputBlog = [
    { label:'New Group Name', value:'' },
];

const AddNewGroupAdmin = ({ userAdminGroups, userIsGroupAdmin, allGroups, userGroups, memberList }) => {
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedMemberId, setSelectedMemberId] = useState('');

    const handleGroupSelect = (groupId) => {
        // Handle the selected group ID here
        setSelectedGroupId(groupId);
      };

    const handleMemberSelect = (memberId) => {
        // Handle the selected member ID here
        setSelectedMemberId(memberId);
    };

    const handleSendInvitation = async () => {
        // Check if a group is selected
        if (!selectedGroupId) {
            // Show a Swal indicating that a group must be selected
            Swal.fire('', 'Please select a group.', 'error');
            return;
        }
        
        // Check if a member is selected
        if (!selectedMemberId) {
            // Show a Swal indicating that a member must be selected
            Swal.fire('', 'Please select a member.', 'error');
            return;
        }
        if (selectedGroupId && selectedMemberId) {
            try {
                const tokenDetailsString = localStorage.getItem('userDetails');
                const tokenDetails = JSON.parse(tokenDetailsString);
                const token = tokenDetails.token;
                // Call your API here using fetch or axios
                const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/new-group-admin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                    groupId: selectedGroupId,
                    userId: selectedMemberId,
                    mode: 1,
                    }),
                });
            
                if (response.ok) {
                    // await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/member-connect`, {
                    //   method: 'POST',
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //   },
                    //   body: JSON.stringify({
                    //     userId:selectedMemberId,
                    //     groupId: 'abcdef',
                    //     mode: '011'
                    //   }),
                    // });
                    Swal.fire('Done!', 'Invitation has been sent.', 'success').then((result) => {
                      // If the user clicks "OK", result.value will be true
                      if (result.isConfirmed) {
                        // Reload the page after the user clicks "OK"
                        window.location.reload();
                      }
                    });
                } else {
                    // Handle API error and show an error Swal
                    Swal.fire('Oops!', 'Try again please.', 'error');
                    if (response.status === 404) {
                        // Handle 404 error (Not Found)
                        Swal.fire('Oops!', 'User not in group.', 'error');
                    }
                }
            } catch (error) {
                if (error.response.status === 404) {
                    // Handle 404 error (Not Found)
                    Swal.fire('Oops!', 'User not in group.', 'error');
                  } else {
                    // Handle other errors
                    Swal.fire('Oops!', 'Something went wrong.', 'error');
                }
            }
        }
    };
    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    
                        <div className="card profile-card card-bx">
                            <form className="profile-form">
                                <div className="card-body">
                                    <GroupList userAdminGroups={userAdminGroups}  userIsGroupAdmin={userIsGroupAdmin} allGroups={allGroups} userGroups={userGroups} onGroupSelect={handleGroupSelect} />
                                    <GroupMemberList memberForGroup={selectedGroupId} memberList={memberList} onMemberSelect={handleMemberSelect} />
                                </div> 
                                &nbsp;
                                <div className="row">
                                    <button type="button" className="btn btn-primary" onClick={handleSendInvitation}>Add</button>
                                </div>
                            </form>
                        </div>
                        
                </div>
            </div>
        </>
    )
}
export default AddNewGroupAdmin;