import React, { useState } from "react";
import GroupList from "../../../components/bootstrap/PredictionsListGroup";
import Swal from "sweetalert2";

const inputBlog = [{ label: "New member email", value: "" }];

const AddNewMember = ({  userAdminGroups, userIsGroupAdmin, userGroups, allGroups }) => {
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [email, setEmail] = useState("");

  const handleGroupSelect = (groupId) => {
    // Handle the selected group ID here
    setSelectedGroupId(groupId);
  };

  const handleEmailChange = (e) => {
    // Update the email state on input change
    setEmail(e.target.value);
  };

  const handleSendInvitation = async () => {
    // Check if a group is selected
    if (!selectedGroupId) {
      // Show a Swal indicating that a group must be selected
      Swal.fire("Oops!", "Please select a group first.", "error");
      return;
    }

    // Check if the email input is not empty
    if (email.trim() === "") {
      // Show a Swal indicating that the email input is required
      Swal.fire("Oops!", "Please select a valid email.", "error");
      return;
    }
    if (selectedGroupId && email.trim() !== "") {
      try {
        const tokenDetailsString = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(tokenDetailsString);
        const token = tokenDetails.token;
        // Call your API here using fetch or axios
        const response = await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/new-member`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              groupId: selectedGroupId,
              email: email,
              mode: 0,
            }),
          }
        );

        if (response.ok) {
          // Show a success Swal or perform the desired action
          Swal.fire("Done!", "New group member added.", "success").then(
            (result) => {
              // If the user clicks "OK", result.value will be true
              if (result.isConfirmed) {
                // Reload the page after the user clicks "OK"
                window.location.reload();
              }
            }
          );
        } else {
          // Handle API error and show an error Swal
          Swal.fire("Oops!", "Try again please.", "error");
        }
      } catch (error) {
        console.error("Error sending invitation:", error);
        // Show an error Swal for unexpected errors
        Swal.fire("Oops!", "Try again please.", "error");
      }
    }
  };
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card profile-card card-bx">
            <form className="profile-form">
              <div className="card-body">
                <GroupList userAdminGroups={userAdminGroups} userIsGroupAdmin={userIsGroupAdmin} allGroups={allGroups}
                  userGroups={userGroups}
                  onGroupSelect={handleGroupSelect}
                />
                &nbsp;
              </div>
              {userGroups.length !== 0 && (
                <div className="card-body">
                  <div>
                    {inputBlog.map((item, ind) => (
                      <div className="" key={ind}>
                        <label className="form-label">{item.label}</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={item.value}
                          onChange={handleEmailChange}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              &nbsp;
              {userGroups.length !== 0 && (
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSendInvitation}
                  >
                    Send Invitation
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddNewMember;
