import React, { Fragment, useState } from "react";

import NavHader from "./NavHader";
import Header from "./PredictionsUserMenu";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");

  const [closeDropdown, setCloseDropdown] = useState(false);

  const onClick = (name) => {
    setToggle(toggle === name ? "" : name);
    setCloseDropdown(true);
  };

  const handleDropdownClose = () => {
    setCloseDropdown(false);
  };

  return (
    <Fragment>
      <NavHader />
        <Header
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            onBox={() => onClick("box")}
            onClick={() => ClickToAddEvent()}
          /> 
    </Fragment>
  );
};

export default JobieNav;
