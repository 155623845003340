import React, { useState, useEffect } from 'react';
import { Dropdown } from "react-bootstrap";
import Column from "../Dashboard/elements/DataBucketlist";
import { Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';

function HeadOne({ bgcolor }) {
  return ''; // Placeholder, you can modify this as needed
}

export default function TaskSummaryBlog() {
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sliderValues, setSliderValues] = useState({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});  // Import setSelectedDropdownValues
  const [bucketlistList, setBucketlistList] = useState({
    tasks: {},
    columns: {},
    columnOrder: [],
  });
  const [showForm, setShowForm] = useState(false); // State to manage the visibility of the form
  const [newItemDescription, setNewItemDescription] = useState(''); // State to store the new bucket list item description
  const [selectedOption, setSelectedOption] = useState('Option1'); // Default selected option

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  
  const updateTotalCount = (updatedCount) => {
    setTotalCount(updatedCount);
  };

  const initializeDropdownAndSliderValues = () => {
    const initialDropdownValues = {};
    const initialSliderValues = {};

    // Convert bucketlistList.tasks to an array
    const tasksArray = Object.values(bucketlistList.tasks);

    // Assuming that the API response is an array of tasks
    for (const task of tasksArray) {
      initialDropdownValues[task.id] = task.select;
      initialSliderValues[task.id] = task.score;
    }

    setSliderValues(initialSliderValues);
    setSelectedDropdownValues(initialDropdownValues);
  };

  const fetchBucketlist = async () => {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    let retries = 0;
    const maxRetries = 5; // Adjust as needed

    while (retries < maxRetries) {
      try {
        let groupName = localStorage.getItem('user_initial_Group');

        // Retry only if groupName is not set
        if (!groupName) {
          retries++;
          console.log(`Retrying fetchBucketlist - Attempt ${retries}`);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
          continue;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/bucketlist/${groupName}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const transformedData = transformResponse(response.data);
          setBucketlistList(transformedData);
          break; // Break out of the loop if successful
        } else {
          console.error('Failed to fetch bucketlist');
        }
      } catch (error) {
        console.error('Error fetching bucketlist:', error);
        retries++;
        console.log(`Retrying fetchBucketlist - Attempt ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      }
    }
  };

  const transformResponse = (data) => {
    // Your transformation logic here
    const tasks = {};
    const columns = {
      "column-1": {
        id: "column-1",
        title: <HeadOne bgcolor="primary" />,
        taskIds: [],
      },
    };

    data.forEach((item, index) => {
      const taskId = index + 1;
      tasks[taskId] = {
        id: item._id,
        content: item.item,
        answer: '',
        select: item.status,
        score: item.score,
        created: item.addedAt,
        updated: item.statusChangedAt,
      };

      columns["column-1"].taskIds.push(taskId);
    });

    const columnOrder = ["column-1"];

    return {
      tasks,
      columns,
      columnOrder,
    };
  };

  useEffect(() => {
    fetchBucketlist();
    const intervalId = setInterval(() => {
      fetchBucketlist();
    }, process.env.REACT_APP_REFRESH_RATE);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (bucketlistList && bucketlistList.columnOrder.length > 0) {
      initializeDropdownAndSliderValues();
    }
  }, [bucketlistList]);

  const handleAddYoursClick = () => {
    setShowForm(true);
  };

  const handleSaveClick = async () => {
    try {
      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem('userDetails');
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;
      
      // Make the API call to update the MongoDB document
      const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/addNewBucketlistItem`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "groupName": localStorage.getItem('user_initial_Group'),
          "item": newItemDescription,
        }),
      });

      if (response.ok) {
        Swal.fire('Nice!', 'Thanks for sharing.', 'success').then((result) => {
          if (result.isConfirmed) {
            setNewItemDescription('');
            setShowForm(false);
            fetchBucketlist();
          }
        });
      } else {
        console.error('Failed to save announcement');
      }

    } catch (error) {
      console.error('Error while saving announcement:', error);
    }
  };

  const handleCancelClick = () => {
    // Reset the form and hide it
    setNewItemDescription('');
    setShowForm(false);
  };
  
  const options = ['Life', 'Health', 'Education', 'Professional', 'Travel', 'Other'];

  return (
    <div className="container-fluid">
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
                <div  className="profile-form">
                  {/* Conditionally render the form based on showForm state */}
                  {showForm ? (
                    <>
                      <button onClick={handleSaveClick} className="btn btn-primary dz-xs-flex">
                        Save
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button onClick={handleCancelClick} className="btn btn-secondary dz-xs-flex">
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={handleAddYoursClick}
                      className="btn btn-primary dz-xs-flex"
                      style={{ backgroundColor: '#FFF8AF', width: '120px', height: '28px', margin: '0px', padding: '0px' }}
                    >
                      Add Yours
                    </button>
                  )}
                  <p> </p>
                  <div>
                    {/* Placeholder for the new bucket list form */}
                    {/* You can customize this section based on your form requirements */}
                    {showForm && (
                      <form>
                        <div className="form-row align-items-center">
                          <div className="col-xl-1">
                            <div className="form-group d-flex">
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle as="div"
                                  style={{ 
                                    border: "1px solid white", color: "red", height: "40px",
                                    margin: "2px",
                                    padding: "8px" 
                                  }}
                                >
                                  Category
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="task-drop-menu"
                                style={{ border: "1px solid gray" }}>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Life
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Health
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Education
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Travel
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Professional
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        style={{ border: "1px solid white", color: "green" }}
                                    >
                                        Other
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              <input
                                className="form-control ml-2"
                                type="text"
                                value={newItemDescription}
                                onChange={(e) => setNewItemDescription(e.target.value)}
                                placeholder="Enter your bucketlist item"
                              />
                            </div>
                          </div>
                        </div>
                        &nbsp;
                      </form>
                    )}
                  </div>
                  <p> </p>
                </div>
              </Col>
              <Col xl={12}>
                <div>
                  {bucketlistList.columnOrder.map((columnId, index) => {
                    const column = bucketlistList.columns[columnId];
                    const tasks = column.taskIds.map((taskId) => bucketlistList.tasks[taskId]);

                    // Pass setSelectedDropdownValues to Column component
                    return (
                      <Column
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        updateTotalCount={updateTotalCount}
                        sliderValuesProp={sliderValues}
                        selectedDropdownValues={selectedDropdownValues}
                        setSelectedDropdownValues={setSelectedDropdownValues} // Pass the function
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
}
