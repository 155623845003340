import React,{useState} from 'react';
import Swal from 'sweetalert2';

const inputBlog = [
    { label:'New member email', value:'' },
];

const AddNewGlobalAdmin = ({ userGroups }) => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        // Update the email state on input change
        setEmail(e.target.value);
    };

    const handleSendInvitation = async () => {
        // Check if the email input is not empty
        if (email.trim() === "") {
            // Show a Swal indicating that the email input is required
            Swal.fire('Oops!', 'Please enter a valid email address.', 'error');
            return;
        }
        if (email.trim() !== "") {
            try {
                const tokenDetailsString = localStorage.getItem('userDetails');
                const tokenDetails = JSON.parse(tokenDetailsString);
                const token = tokenDetails.token;
                // Call your API here using fetch or axios
                const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/new-global-admin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                    email: email,
                    mode: 1,
                    }),
                });
            
                if (response.ok) {
                    await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/member-connect`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        userId:email,
                        groupId: 'abcdef',
                        mode: '015'
                      }),
                    });
                    Swal.fire('Done!', 'Invitation has been sent.', 'success').then((result) => {
                      // If the user clicks "OK", result.value will be true
                      if (result.isConfirmed) {
                        // Reload the page after the user clicks "OK"
                        window.location.reload();
                      }
                    });
                } else {
                    // Handle API error and show an error Swal
                    Swal.fire('Oops!', 'Something went wrong.', 'error');
                }
            } catch (error) {
                console.error('Error sending invitation:', error);
                // Show an error Swal for unexpected errors
                Swal.fire('Oops!', 'Something went wrong.', 'error');
            }
        }
    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    
                        <div className="card profile-card card-bx">
                            <form className="profile-form">
                                <div className="card-body">
                                    <div>
                                    {inputBlog.map((item, ind) => (
                                        <div className="" key={ind}>
                                        <label className="form-label">{item.label}</label>
                                        <input type="text" 
                                        className="form-control" 
                                        defaultValue={item.value} 
                                        onChange={handleEmailChange} />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                &nbsp;
                                <div className="row">
                                    <button type="button" className="btn btn-primary" onClick={handleSendInvitation}>Send Invitation</button>
                                </div>
                            </form>
                        </div>
                        
                </div>
            </div>
        </>
    )
}
export default AddNewGlobalAdmin;