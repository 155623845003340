import React, { useState, useEffect } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const Column = ({ column, tasks, updateTotalCount, currentGameGoals }) => {
  const [ownItLabels, setOwnItLabels] = useState({});
  
  const [sliderValues, setSliderValues] = useState([0]);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState(
    tasks.reduce((acc, task) => {
      acc[task.id] = "";
      return acc;
    }, {})
  );
  const [isSliderModified, setIsSliderModified] = useState(
    tasks.map(() => false)
  );
  const [hiddenTasks, setHiddenTasks] = useState([]);

  useEffect(() => {
    if (sliderValues) {
      const hasSliderChanged = sliderValues.map(
        (value, index) => value !== tasks[index]?.score
      );
      setIsSliderModified(hasSliderChanged);
    }
  }, [sliderValues, tasks]);

  useEffect(() => {
    const taskScoreList = tasks.map((task) => task.score || 0);
    setSliderValues(taskScoreList);
  }, [tasks]);

  const handleDropdownChange = async (item, value) => {
    console.log(item);
    try {
      // Calculate the total count directly
      const updatedCount = tasks.filter(
        (task) => task.select === "Correct"
      ).length;

      // Call the updateTotalCount function with the correct argument
      updateTotalCount(updatedCount);

      // Update the UI state
      setSelectedDropdownValues((prev) => ({ ...prev, [item.id]: value }));

      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem("userDetails");
      let groupName = localStorage.getItem("user_initial_Group");

      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      // Make the API call to update the MongoDB document
      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/updateGoal`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: item.id,
            status: value,
            item: item.content,
            year: new Date().getFullYear(),
            groupName: groupName,
          }),
        }
      );

      // Check if the update was successful
      if (response.status === 200) {
        // Add any additional logic or feedback if needed
      } else {
        console.error("Failed to update task:", response.data);
        // Handle errors or provide feedback to the user
      }
    } catch (error) {
      console.error("An error occurred while updating the task:", error);
      // Handle errors or provide feedback to the user
    }
  };

  const handleSliderChange = (id, value, index) => {
    // Check if tasks[index] is defined before accessing its properties
    if (tasks[index]) {
      // Handle slider change
      const newSliderValues = [...sliderValues];
      newSliderValues[index] = Number(value);
      setSliderValues(newSliderValues);

      // Update the modified state for the current slider
      const newIsSliderModified = [...isSliderModified];
      newIsSliderModified[index] = value !== tasks[index].score;
      setIsSliderModified(newIsSliderModified);

      // Call the handleSliderChange function if needed
      // handleSliderChange(id, value);
    }
  };

  const handleSaveButtonClick = async (item, index) => {
    try {
      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem("userDetails");
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;
      let groupName = localStorage.getItem("user_initial_Group");

      // Make the API call to update the MongoDB document
      const response = await fetch(
        `${process.env.REACT_APP_PREDICTIONS_API}/api/updateGoal`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: item.id,
            score: sliderValues[index], // Use the slider value as the score
            item: item.content,
            year: new Date().getFullYear(),
            groupName: groupName,
          }),
        }
      );

      // Check if the update was successful
      if (response.status === 200) {
        // Add any additional logic or feedback if needed
      } else {
        console.error("Failed to update task:", response.data);
        // Handle errors or provide feedback to the user
      }
    } catch (error) {
      console.error("An error occurred while updating the task:", error);
      // Handle errors or provide feedback to the user
    }
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Get the formatted date string
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );

    // Remove the comma after the weekday
    return formattedDate.replace(/,/g, "");
  };

  const [buttonTextMap, setButtonTextMap] = useState({});
  
  const handleOwnItClick = (goalItem) => {
    Swal.fire({
      text: 'Make this goal your own?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Prepare the data to be sent to the API
        const tokenDetailsString = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(tokenDetailsString);
        const token = tokenDetails.token;
  
        // Make the API call to update the MongoDB document
        const response = await fetch(
          `${process.env.REACT_APP_PREDICTIONS_API}/api/addNewGoalItem`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              groupName: localStorage.getItem("user_initial_Group"),
              item: goalItem,
            }),
          }
        );
  
        if (response.ok) {
          Swal.fire("Awesome!", "Goal will be added.", "success").then((result) => {
            if (result.isConfirmed) {
              // Disable the button for the clicked goalItem
              setOwnItLabels((prevLabels) => ({
                ...prevLabels,
                [goalItem]: 'added',
              }));
            }
          });
        } else {
          console.error("Failed to save goal");
        }
      }
    });
  };

  return (
    <div className="col" style={{ backgroundColor: "#01152F" }}>
      <div className="card kanbanPreview-bx">
        <div className="card-body">
          {column.title}
          <div className="h-100">
            {tasks.map((task, index) => {
                if (hiddenTasks.includes(task.id)) {
                  return null; // Skip rendering if the task is hidden
                }
              return (
                <div
                  className="sub-card p-0"
                  key={task.id}
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="task-card-data"
                    style={{ backgroundColor: "#78CFB" }}
                  >
                    <div className="products">
                      <div>
                        <h3>{task.content}</h3>
                        {task.mode !== "template" ? (
                          <>
                            <h4>
                              Status: {task.select} &nbsp;|&nbsp;&nbsp;Score: {task.score}%
                            </h4>
                            <h6 style={{ color: "#6789AB" }}>
                              Updated: {task.update ? formatDate(task.updated) : ""}
                            </h6>
                            <h6 style={{ color: "#6789AB" }}>
                              Added: {formatDate(task.created)}
                            </h6>
                          </>
                        ) : (
                          <div><h3>&nbsp;</h3></div>
                        )}
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                        {/* Conditional rendering for buttons */}
                        {task.mode === "template" ? (
                          <div>
                            <button
                              onClick={() => handleOwnItClick(task.content)}
                              className="btn btn-primary dz-xs-flex"
                              style={{
                                color: "black",
                                backgroundColor: "#FFF8AF",
                                width: "100px",
                                height: "28px",
                                margin: "0px",
                                padding: "0px",
                              }}
                              disabled={ownItLabels[task.content] === 'added'}
                            >
                              {ownItLabels[task.content] || 'keep'}
                            </button>
                        </div>
                        ) :
                          <div className="footer-data">
                            <span>
                              <h6>
                                <font color="gray">update status: </font>
                              </h6>
                            </span>
                            <p>
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle as="div" className={task.select}>
                                  {selectedDropdownValues[task.id] || task.select}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className="task-drop-menu"
                                  style={{ border: "1px solid gray" }}
                                >
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleDropdownChange(task, "Not Started")
                                    }
                                    style={{
                                      border: "1px solid white",
                                      color: "red",
                                    }}
                                  >
                                    Not Started
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleDropdownChange(task, "In Progress")
                                    }
                                    style={{
                                      border: "1px solid white",
                                      color: "orange",
                                    }}
                                  >
                                    In Progress
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleDropdownChange(task, "Completed")
                                    }
                                    style={{
                                      border: "1px solid white",
                                      color: "green",
                                    }}
                                  >
                                    Completed
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </p>
                          </div>
                      }
                      <div className="footer-data"></div>
                      <div className="footer-data">
                      {task.mode !== "template" ? (
                        <div>
                          {/* {(currentGameGoals !== "beginScoring") ? ( */}
                          {(2 !== 2) ? (
                                <div>
                                  update and share
                                </div>
                            ) : (
                              <div>
                                <span className="d-block">
                                  <h6
                                    style={{
                                      color:
                                        sliderValues[index] === 0
                                          ? "red"
                                          : sliderValues[index] === 100
                                          ? "green"
                                          : "orange",
                                    }}
                                  >
                                    {isSliderModified[index] && (
                                      <button
                                        onClick={() => handleSaveButtonClick(task, index)}
                                        type="submit"
                                        className="btn btn-primary dz-xs-flex m-r5"
                                        style={{
                                          width: "42px",
                                          height: "28px",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        Save
                                      </button>
                                    )}{" "}
                                    <font color="gray">update score:</font> {sliderValues[index]}
                                  </h6>
                                </span>
                                <div>
                                  <RangeSlider
                                    value={sliderValues[index]}
                                    onChange={(e) => {
                                      const newSliderValues = [...sliderValues];
                                      newSliderValues[index] = Number(e.target.value);
                                      setSliderValues(newSliderValues);
                                      handleSliderChange(
                                        task.id,
                                        e.target.value === "100" ? "Correct" : "Wrong"
                                      );
                                    }}
                                    tooltip="off"
                                    step={25}
                                    min={0}
                                    max={100}
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div>
                          <h6 style={{ color: '#808080'}}> <em>group admin suggestion</em></h6>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Column;
