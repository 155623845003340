import React from 'react';
import { Row, Col } from "react-bootstrap";

export default function TaskSummaryBlog() {
  
  return (
    <div className="container-fluid" style={{ backgroundColor: 'rgba(1, 21, 47, 0.3)' }}>
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
                <Col xl={12}>
                    <div  className="profile-form">
<h3 style={{ color: '#AAAAAA' }}>
Happy New Year! It's time to make your predictions and set some goals!
</h3>
<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Making your Predictions! 
</h3>
<h6>&nbsp;</h6>
<h4 style={{ color: '#AAAAAA' }}>
<u>Group Member:</u>
</h4>
<h5 style={{ color: '#AAAAAA' }}>
Can you predict the future?  Select the Predictions icon at the bottom of the screen. 
</h5>
<h5 style={{ color: '#AAAAAA' }}>
You will see prompted questions for the coming year, thanks to your group admin!  Go ahead and answer the questions predicting what YOU think will happen in the New Year.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
After you have entered your predictions, select “Save Answers”.   Now your predictions are sealed and locked!   No peeking until next New Year's Eve!
</h5>
<h5 style={{ color: '#AAAAAA' }}>
On next year's New Year's Eve, your Group Admin will do the “Big Reveal” and you will see your sealed responses again. How well did you predict the answers to the questions? Score your responses by selecting if you were 0%, 25, 50%, 75%, or 100% correct. Be sure to select “Save Answers” after you score your predictions.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Select the Home icon to see your total Predictions scoring.
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Achieve your Goals!
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
<u>Group Member:</u>
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Got some goals for the New Year? Everyone needs a goal to work towards.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Select the “Goals” icon at the bottom of your screen and select the “Add Yours” button.
</h5>
<h5 style={{ color: '#AAAAAA' }}> 
Create 1-5 goals you want to achieve in the New Year.  Select save for each one. 
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Your Group Admin may create up to 5 goals as suggestions for your group.  If you do not see these goals on the screen, stay tuned!
</h5>
<h5 style={{ color: '#AAAAAA' }}>
You can decide if you want to “Keep” each suggested goal by selecting the “Keep” button.  If you don't want to keep the suggested goal, do nothing, it will be removed after you select “Save”.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Goals will be locked at the end of January by the Global Administrator.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
<em>Updating your goals:</em>  Share your progress with your group during the year! 
Update your status to from “Not started” to “In Progress” to “Completed”. Tap the slider to update your progress to 25%, 50% or 75%.  When you are “Completed”, give yourself 100%!  Select “Save” after you update the progress of each goal.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Snap a pic and upload your photo by selecting the “Announcements” icon to share what you are working towards during the year. 
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Select the Home icon to see your total Goals scoring.
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Scoring
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
&nbsp;&nbsp;&middot;&nbsp;Goal scoring is based on 5 goals for a total of 500 points maximum.   
</h5>
<h5 style={{ color: '#AAAAAA' }}>
&nbsp;&nbsp;&middot;&nbsp;Predictions scoring is based on 100 points per question. 
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Your predictions and goal answers are combined for an overall average score based on your ability to predict the future and your goal achievement.  Well done!
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Sharing Pictures
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
You can add pictures to your group carousel display of photos.  You can also share updates of your goal progress.  Within your group, select the Announcement icon and select “Add Yours”.  You can add a note and upload your photo by selecting choose file. 
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Create a New Group
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
Click on your profile and select “Create a new Group”.  Enter a group name, select “Add”.  Group Admin menu options will now be available for you to add group members, create your group's Predictions and goals. 
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Group Admin
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
A few quick tips if you are a Group Admin
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Select Admin options from the menu at the top right of the page after you login.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
<em>Create your group's Predictions Question list:  A standard template has been created to get you started! You can use the standard questions already created or you can add new questions and delete questions. When you have your list ready, select “Publish.” </em>
</h5>
<h5 style={{ color: '#AAAAAA' }}>
<em>Create your group's Goals:</em><br />You may enter up to 5 goals for your group. When you have your goals ready, select “Publish”.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
<em>Create a new group:</em><br />Enter a group name, select “Add”.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
<em>Invite a new group member:</em><br />Select your group name from the drop-down menu, enter your new member's email address and select “Send Invitation”. Enter an email address for each new member you want to add to the group. 
want to add to the group.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
Now you are ready to make your predictions and set your goals with your group!
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
Seal the Predictions
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
After all group members have entered their predictions, select “Lock” to lock the predictions.   Answers remain locked until the big reveal at next year's party.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
If the Group Admin doesn't lock the predictions, they will be locked at the end of January by the Global Administrator.
</h5>

<h6>&nbsp;</h6>
<h3 style={{ color: '#AAAAAA' }}>
The Big Reveal
</h3>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
At the next New Year's Eve party, the Group Admin do the “Big Reveal” so your members can see their answers and score their results.   
</h5>
<h5 style={{ color: '#AAAAAA' }}>
&nbsp;&nbsp;&middot;&nbsp;Select “Reveal” on the predictions page for the current year.
</h5>
<h5 style={{ color: '#AAAAAA' }}>
&nbsp;&nbsp;&middot;&nbsp;You will see “Start your NYE Party”, select “Ok”.  
</h5>
<h5 style={{ color: '#AAAAAA' }}>
&nbsp;&nbsp;&middot;&nbsp;Slide the button from “Sealed” to “Reveal”.  The prompt “Ready for the Big Reveal?” appears, Select “Ok”. 
</h5>
<h5 style={{ color: '#AAAAAA' }}>
After all members have scored how well they predicted the future, it's time to close the year.  Select “Close the “20XX” Party.
</h5>
<h6>&nbsp;</h6>
<h5 style={{ color: '#AAAAAA' }}>
Be sure to create your next year’s predictions questions and set your goals for the next year!
</h5>
<h6>&nbsp;</h6>
&nbsp;
                    </div>
                    &nbsp;
                </Col>
            </Row>
            &nbsp;
          </div>
          &nbsp;
        </div>
        &nbsp;
      </div>
    </div>
  );
}
