import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import axios from 'axios';

const StatsTab = () => {
  const [goalsData, setGoalsData] = useState([]);
  const [predictionsData, setPredictionsData] = useState([]);
  const [leaderData, setLeaderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let retries = 0;
      const maxRetries = 5; // Adjust as needed
  
      while (retries < maxRetries) {
        try {
          let currentGroup = localStorage.getItem("user_initial_Group");
  
          // Retry only if currentGroup is not set
          if (!currentGroup) {
            retries++;
            // console.log(`Retrying fetchData - Attempt ${retries}`);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
            continue;
          }
  
          // Fetch goals data
          const goalsApiUrl = `${process.env.REACT_APP_PREDICTIONS_API}/api/user-goal-scores/${currentGroup}`;
          const goalsResponse = await axios.get(goalsApiUrl);
          setGoalsData(goalsResponse.data);
  
          // Fetch predictions data
          const predictionsApiUrl = `${process.env.REACT_APP_PREDICTIONS_API}/api/group-predictions-scores/${currentGroup}`;
          const predictionsResponse = await axios.get(predictionsApiUrl);
          setPredictionsData(predictionsResponse.data);
  
          // Combine and calculate the leaderboard
          const combinedLeaderboard = combineLeaderboards(predictionsResponse.data, goalsResponse.data);
          setLeaderData(combinedLeaderboard);
  
          setIsLoading(false); // Data has been loaded, update loading state
          return; // Exit the loop if successful
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false); // Set loading state to false even in case of an error
  
          // Retry only if it's not the last attempt
          if (retries < maxRetries) {
            retries++;
            // console.log(`Retrying fetchData - Attempt ${retries}`);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
          } else {
            console.error("Max retries reached. Unable to fetch data.");
          }
        }
      }
    };
  
    fetchData(); // Initial data fetch

    const refreshRate = process.env.REACT_APP_REFRESH_RATE; // Default to 60 seconds if not specified
  
    const refreshInterval = setInterval(() => {
      fetchData(); // Fetch data at the specified interval
    }, refreshRate);
  
    // Cleanup the interval to avoid memory leaks
    return () => clearInterval(refreshInterval);
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // Combine and calculate the leaderboard
  const combineLeaderboards = (predictions, groups) => {
    const userScores = new Map();

    // Process prediction data
    predictions.forEach(prediction => {
      const userId = prediction.userId;
      const totalScore = parseInt(prediction.total);

      if (!userScores.has(userId)) {
        userScores.set(userId, { title: prediction.title, userId, totalScore, score: prediction.score });
      } else {
        userScores.get(userId).totalScore += totalScore;
      }
    });

    // Process group data
    groups.forEach(group => {
      const userId = group.userId;
      const totalScore = parseInt(group.total);

      if (!userScores.has(userId)) {
        userScores.set(userId, { title: group.title, userId, totalScore, score: group.score });
      } else {
        userScores.get(userId).totalScore += totalScore;
      }
    });

    // Convert map values to an array
    const combinedData = Array.from(userScores.values());

    // Sort the combined data based on the total score
    combinedData.sort((a, b) => b.totalScore - a.totalScore);

    // Add rank and rating to combined leaderboard
    combinedData.forEach((user, index) => {
      user.rank = index + 1;
      user.rating = calculateRating(index); // Add rating based on index
    });

    return combinedData;
  };

  // Function to calculate rating based on order (descending)
  const calculateRating = (index) => {
    switch (index) {
      case 0:
        return 4;
      case 1:
        return 3;
      case 2:
        return 2;
      default:
        return 0;
    }
  };

  // Get badge color based on rating
  const getBadgeColor = (rating) => {
    switch (parseInt(rating)) {
      case 4:
        return "green";
      case 3:
        return "yellow";
      case 2:
        return "orange";
      default:
        return "red";
    }
  };

  // Set font size based on rating
  const getFontSize = (rating) => {
    return { fontSize: "1.2rem" };
  };

  if (isLoading) {
    return <p>Loading...</p>; // Display a loading message or spinner while waiting for data
  }

  return (
    <div className="container-fluid">
      <div className="element-area">
        <div className="demo-view">
          <div className="container-fluid pt-0 ps-0 pe-lg-4 pe-0">
            <Row>
              <Col xl={12}>
                <Tab.Container defaultActiveKey="Leaderboard">
                  <Card name="custom-tab" className="dz-card">
                    <Card.Body>
                      <Nav as="ul" className="nav-tabs justify-content-center">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Leaderboard">
                            Winners
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Goals">
                            Goals
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Predictions">
                            Predictions
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="Leaderboard">
                          <div className="table-responsive active-projects manage-client" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                              <tbody>
                                {leaderData.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td width="70%">
                                        <Link to={"#"} key={index}>{item.title}</Link>
                                      </td>
                                      <td width="10%">
                                        <i className={`fa-sharp fa-solid fa-user-shield text-${getBadgeColor(item.rating)}`} style={getFontSize(item.rating)}></i>
                                      </td>
                                      <td width="10%">
                                        {item.totalScore}
                                      </td>
                                      <td width="10%">
                                        {item.score}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Goals">
                          <div className="table-responsive active-projects manage-client" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                              <tbody>
                                {goalsData.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td width="70%">
                                        <Link to={"#"} key={index}>{item.title}</Link>
                                      </td>
                                      <td width="10%">
                                        <i className={`fa-sharp fa-solid fa-user-shield text-${getBadgeColor(item.rating)}`} style={getFontSize(item.rating)}></i>
                                      </td>
                                      <td width="10%">
                                        {item.total}
                                      </td>
                                      <td width="10%">
                                        {item.score}
                                      </td> 
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Predictions">
                          <div className="table-responsive active-projects manage-client" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                              <tbody>
                                {predictionsData.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td width="70%">
                                        <Link to={"#"} key={index}>{item.title}</Link>
                                      </td>
                                      <td width="10%">
                                        <i className={`fa-sharp fa-solid fa-user text-${getBadgeColor(item.rating)}`} style={getFontSize(item.rating)}></i>
                                      </td>
                                      <td width="10%">
                                        {item.total}
                                      </td>
                                      <td width="10%">
                                        {item.score}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Card.Body>
                  </Card>
                </Tab.Container>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsTab;
