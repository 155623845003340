import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Card, Col, ListGroup, Badge, Tab, Nav, Dropdown } from "react-bootstrap";
import axios from 'axios';

const PredictionsListGroupMembers = ({ memberForGroup, memberList, onMemberSelect }) => { 
  const nav = useNavigate();
  const [selectedMember, setSelectedMember] = useState('');
  const [listItem, setListItem] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Get the token from localStorage
        const tokenDetailsString = localStorage.getItem('userDetails');
		    const tokenDetails = JSON.parse(tokenDetailsString);
		    const token = tokenDetails.token;

        // Make a GET request to the API endpoint with the Bearer token
        const response = await axios.get(`${process.env.REACT_APP_PREDICTIONS_API}/api/getUsers`, {
          params: {
            memberForGroup: memberForGroup, // Replace with the actual value
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Update the state with the new data received from the API
        setListItem(response.data.data.users.map(user => ({
          id: user._id,
          name: user.names
        })));
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [memberForGroup]); // Dependency array ensures the effect runs only when the component mounts

  const handleMemberSelect = (memberId) => {
    setSelectedMember(memberId);
    // Call the callback function with the selected Member ID
    onMemberSelect(memberId);
  };
  
  return (
    memberForGroup ? (
    <Fragment>
    <div className="container-fluid">
      <div className="row">
        <label className="form-label">Select a group member</label>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic"
              style={{ backgroundColor: 'gray', width: '270px', fontSize: '16px' }}
          >
            {listItem.find(item => item.id === selectedMember)?.name ?? 'Select Group Member'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {listItem
              .filter((item) => item.id !== 'b66ab7dd-0b80-43d8-91dd-5626492f926b')
              .map((item) => (
              <Dropdown.Item
                key={item.id}
                onClick={() => handleMemberSelect(item.id)}
                active={selectedMember === item.id}
                style={{ backgroundColor: 'white' }}
              >
                <div>
                  <h4>{item.name}</h4>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div> 
  </Fragment> 
  ) : null  
  );
};

export default PredictionsListGroupMembers;
