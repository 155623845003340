import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { addNewGroupAction } from "../../../../store/actions/AuthActions";

const inputBlog = [
  { label: 'New Group Name', value: '' }, 
];

const NewGroupName = () => {
  const [groupName, setGroupName] = useState('');
  let errorsObj = { groupName: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleAddGroup = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (groupName === "") {
      errorObj.groupName = "Group Name is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    // Make API call to add new group
    try {
        setLoader(true);
    
        // Assuming addNewGroup is an async action that adds the new group
        const response = await dispatch(addNewGroupAction({ groupName }));
    
        setLoader(false);
        
      } catch (error) {
        setLoader(false);
        console.error('Error adding new group:', error);
      }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card profile-card card-bx">
            <form className="profile-form">
              <div className="card-body">
                <div>
                  {inputBlog.map((item, ind) => (
                    <div className="" key={ind}>
                      <label className="form-label">{item.label}</label>
                      <input
                        id="newGroupName"
                        name="newGroupName"
                        type="text"
                        className="form-control"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      />
                      {errors.groupName && (
                        <div className="text-danger fs-12">
                          {errors.groupName}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              &nbsp;
              <div className="row">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddGroup}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewGroupName;
