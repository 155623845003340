export const isAuthenticated = (state) => {
    const storageDetails = localStorage.getItem('userDetails');
    if (storageDetails) {
        const storageData = JSON.parse(storageDetails);
        const expireDate = new Date(storageData.expireDate);
        const currentDate = new Date();
        if (
            state.auth.auth.idToken && 
            state.auth.auth.accessToken && 
            storageData.data.email &&
            storageData.data.accessToken &&
            expireDate > currentDate
           ) return true;
    }
    return false;
};
