import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userUpdateAction } from "../../../../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const EditProfile = ({ profileImage, currentUser }) => {
  const state = useSelector((state) => state);

  const nav = useNavigate();
  const [email, setEmail] = useState(currentUser.email);
  const [password, setPassword] = useState("");
  const [name, setName] = useState(currentUser.names);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  let errorsObj = { email: "", name: "" };
  const [errors, setErrors] = useState(errorsObj);
  const updateProfile = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    // Make API call to update profile
    try {
        // Assuming userUpdateAction is an async action that updates the user profile
        setLoader(true);
        dispatch(userUpdateAction({ email, name, password }));
        const storedUserDetails = localStorage.getItem('userDetails');
        if (storedUserDetails) {
            const { data, token, expireDate } = JSON.parse(storedUserDetails);
            const updatedUser = {
                ...data,
                names: name,
                email: email,
            };
            localStorage.setItem('userDetails', JSON.stringify({ data: updatedUser, token, expireDate }));
            currentUser.names = name;
            currentUser.email = email;
        }
        setLoader(false);
    } catch (error) {
        setLoader(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card profile-card card-bx">

            <form
              className="profile-form"
              onSubmit={(e) => {
                e.preventDefault();
                updateProfile();
              }}
            >
              <div className="card-body">
                <div>
                  <div className="mt-4">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                      <div className="text-danger fs-12">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      value={email}
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-danger fs-12">
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <label className="form-label">New Password</label>

                    <input
                      placeholder="(set new password here)"
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              &nbsp;
              <div className="row">
                <button className="btn btn-primary">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
