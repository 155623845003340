import React, { useState, useEffect } from 'react';
import RangeSlider from "react-bootstrap-range-slider";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const Column = ({ column, tasks, updateTotalCount }) => {
  const [sliderValues, setSliderValues] = useState(
    tasks.map((task) => task.score || 0)
  );

  const [selectedDropdownValues, setSelectedDropdownValues] = useState(
    tasks.reduce((acc, task) => {
      acc[task.id] = ''; // task.status || 'not started'; // Set initial dropdown values based on the status in the database
      return acc;
    }, {})
  );  
  
  // Add a state variable to track whether each slider value has been modified
  const [isSliderModified, setIsSliderModified] = useState(
    tasks.map(() => false)
  );

  useEffect(() => {
    // Check if the slider values have changed
    const hasSliderChanged = sliderValues.map(
      (value, index) => value !== tasks[index].score
    );

    // Update the state variable accordingly
    setIsSliderModified(hasSliderChanged);
  }, [sliderValues, tasks]);

  const handleDropdownChange = async (id, value) => {
    try {
      // Calculate the total count directly
      const updatedCount = tasks.filter((task) => task.select === "Correct").length;

      // Call the updateTotalCount function with the correct argument
      updateTotalCount(updatedCount);

      // Update the UI state
      setSelectedDropdownValues((prev) => ({ ...prev, [id]: value }));

      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem('userDetails');
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      // Make the API call to update the MongoDB document
      const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/updateBucketlist`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          status: value, // Use the selected status from the dropdown
        }),
      });

      // Check if the update was successful
      if (response.status === 200) {
        // Add any additional logic or feedback if needed
      } else {
        console.error("Failed to update task:", response.data);
        // Handle errors or provide feedback to the user
      }
    } catch (error) {
      console.error("An error occurred while updating the task:", error);
      // Handle errors or provide feedback to the user
    }
  };

  const handleSliderChange = (id, value, index) => {
    // Check if tasks[index] is defined before accessing its properties
    if (tasks[index]) {
      // Handle slider change
      const newSliderValues = [...sliderValues];
      newSliderValues[index] = Number(value);
      setSliderValues(newSliderValues);
  
      // Update the modified state for the current slider
      const newIsSliderModified = [...isSliderModified];
      newIsSliderModified[index] = value !== tasks[index].score;
      setIsSliderModified(newIsSliderModified);
  
      // Call the handleSliderChange function if needed
      // handleSliderChange(id, value);
    }
  };

  const handleSaveButtonClick = async (id, index) => {
    try {
      // Prepare the data to be sent to the API
      const tokenDetailsString = localStorage.getItem('userDetails');
      const tokenDetails = JSON.parse(tokenDetailsString);
      const token = tokenDetails.token;

      // Make the API call to update the MongoDB document
      const response = await fetch(`${process.env.REACT_APP_PREDICTIONS_API}/api/updateBucketlist`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          status: selectedDropdownValues[id], // Use the selected status from the dropdown
          score: sliderValues[index], // Use the slider value as the score
        }),
      });

      // Check if the update was successful
      if (response.status === 200) {
        // Add any additional logic or feedback if needed
      } else {
        console.error("Failed to update task:", response.data);
        // Handle errors or provide feedback to the user
      }
    } catch (error) {
      console.error("An error occurred while updating the task:", error);
      // Handle errors or provide feedback to the user
    }
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    // Get the formatted date string
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  
    // Remove the comma after the weekday
    return formattedDate.replace(/,/g, '');
  };

  return (
    <div className="col">
      <div className="card kanbanPreview-bx">
        <div className="card-body">
          {column.title}
          <div className="h-100">
            {tasks.map((task, index) => (
              <div className="sub-card p-0" key={task.id} style={{marginBottom: '20px'}}>
                <div className="task-card-data" style={{backgroundColor: '#EFCDAB'}}>
                  <div className="products">
                    <div>
                      <h3>{task.content}</h3>
                      <h4>Status: {task.select} &nbsp;|&nbsp;&nbsp;Score: {task.score}%</h4>
                      <h6 style={{ color: '#AF8B69' }}>Updated: {task.update ? formatDate(task.updated) : ''}</h6>
                      <h6 style={{ color: '#AF8B69' }}>Added: {formatDate(task.created)}</h6>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex align-items-center justify-content-between">
                  <div className="footer-data">
                    <span>
                      <h6><font color="gray">update status: </font></h6>
                    </span>
                    <p>
                      <Dropdown className="task-dropdown-2">
                        <Dropdown.Toggle as="div" className={task.status}>
                          {selectedDropdownValues[task.id] || task.status}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="task-drop-menu"
                        style={{ border: "1px solid gray" }}>
                            <Dropdown.Item
                                onClick={() => handleDropdownChange(task.id, "not started")}
                                style={{ border: "1px solid white", color: "red" }}
                            >
                                Not Started
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleDropdownChange(task.id, "in progress")}
                                style={{ border: "1px solid white", color: "orange" }}
                            >
                                In Progress
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleDropdownChange(task.id, "completed")}
                                style={{ border: "1px solid white", color: "green" }}
                            >
                                Completed
                            </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </p>
                  </div>
                  <div className="footer-data"></div>
                  <div className="footer-data">
                    <div>
                      <span className="d-block">
                        <h6
                          style={{
                            color:
                              sliderValues[index] === 0
                                ? "red"
                                : sliderValues[index] === 100
                                ? "green"
                                : "orange",
                          }}
                        >
                          {isSliderModified[index] && (
                            <button
                              variant="primary"
                              onClick={() => handleSaveButtonClick(task.id, index)}
                              type="submit"
                              className="btn btn-primary dz-xs-flex m-r5"
                              style={{
                                width: "42px",
                                height: "28px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              Save
                            </button>
                          )}{" "}
                          <font color="gray">update score:</font> {sliderValues[index]}
                        </h6>
                      </span>
                    </div>
                    <div>
                      <RangeSlider
                        value={sliderValues[index]}
                        onChange={(e) => {
                          const newSliderValues = [...sliderValues];
                          newSliderValues[index] = Number(e.target.value);
                          setSliderValues(newSliderValues);
                          handleSliderChange(task.id, e.target.value === "100" ? "Correct" : "Wrong");
                        }}
                        tooltip="off"
                        step={25}
                        min={0}
                        max={100}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Column;
